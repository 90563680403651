import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAuthSliceState {
  isLoggedIn: boolean;
}

const initialState: IAuthSliceState = {
  isLoggedIn: localStorage.getItem('tokenAccess') !== null
};

export const AuthSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        localStorage.removeItem('tokenAccess');
        localStorage.removeItem('tokenRefresh');
        localStorage.removeItem('searchValue');
        localStorage.removeItem('filteredEmp');
      }
      state.isLoggedIn = action.payload;
    }
  }
});

export const { setIsLoggedIn } = AuthSlice.actions;
export default AuthSlice.reducer;
