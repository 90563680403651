import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDepartments, IEmployee, ISubdivision } from '../types/OKTypes';

interface IOkSliceState {
  employees: IEmployee[];
  subdivisions: ISubdivision[];
  departments: IDepartments[];
  curDepartment: IDepartments;
}

const initialState: IOkSliceState = {
  employees: [],
  subdivisions: [],
  departments: [],
  curDepartment: {
    alternative_name: null,
    icon: '',
    id: null,
    name: ''
  }
};

export const OKSlice = createSlice({
  name: 'okSlice',
  initialState,
  reducers: {
    setAllDepartments: (state, action: PayloadAction<IDepartments[]>) => {
      state.departments = action.payload;
    },
    setSubdivisions: (state, action: PayloadAction<ISubdivision[]>) => {
      state.subdivisions = action.payload;
    },
    setEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      state.employees = action.payload;
    },
    setCurDepartment: (state, action: PayloadAction<IDepartments>) => {
      state.curDepartment = action.payload;
    }
  }
});

export default OKSlice.reducer;
export const {
  setAllDepartments,
  setSubdivisions,
  setEmployees,
  setCurDepartment
} = OKSlice.actions;
