import instance from './api';

export const apiOK = {
  getStartedEmployees: () => {
    return instance.get('/employees/');
  },
  getDepartmentInfo: (depId: string) => {
    return instance.get(`/departments/${depId}/`);
  },
  getAllDep: () => {
    return instance.get('/departments/');
  },
  getEmployeesFromDep: (depId: string) => {
    return instance.get(`/employees/?department=${depId}`);
  },
  getSubdivisions: () => {
    return instance.get('/subdivisions');
  }
};
