import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from '../../common/Avatar';
import human from '../../../assets/img/profile/human.png';
import {
  useAppDispatch,
  useTypedSelector
} from '../../../hooks/reduxHooks/redux';
import { ServerString } from '../../../config';
import { setIsLoggedIn } from '../../../reduxStore/slices/authSlice';

const ProfileDropdown = () => {
  const dispatch = useAppDispatch();
  const userData = useTypedSelector(state => state.user.userData);

  const logoutLogic = () => {
    dispatch(setIsLoggedIn(false));
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-1 ps-3 py-1 nav-link"
      >
        {userData.photo === null || userData.photo === undefined ? (
          <Avatar
            rounded={'circle'}
            src={human}
            size={'2xl'}
            className="align-middle"
          />
        ) : (
          <Avatar
            rounded={'circle'}
            src={`${ServerString + userData.photo}`}
            size={'2xl'}
            className="align-middle"
          />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-1 me-1 dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={'/employees/profile/'}>
            Профиль
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/settings">
            Настройки
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as={Link}
            to="/authentication/simple/logout"
            onClick={logoutLogic}
          >
            Выход
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
