import instance from '../../api/api';
import {
  addTask,
  changeStatus,
  deleteTask,
  setTasks
} from '../slices/tasksSlice';
import { AppDispatch } from '../index';
import { notifyErr, notifySuc } from '../../helpers/utils';

export const getTasks = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await instance.get(`/tasks/`);

      if (res.status === 200) {
        dispatch(setTasks(res.data));
      }
    } catch (e) {
      console.log(e);
      notifyErr('Ошибка', e);
    }
  };
};

export const addNewTask = (
  title: string,
  description: string,
  executors: number[],
  project?: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await instance.post(`/tasks/`, {
        title: title,
        description: description,
        executors: executors,
        project: project
      });

      if (res.status === 201) {
        dispatch(addTask(res.data));
        notifySuc('Задача успешно добавлена!');
      }
    } catch (e) {
      console.log(e);
      notifyErr('Ошибка', e);
    }
  };
};

export const changeTaskStatus = (taskId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await instance.patch(`/tasks/${taskId}/change_status/`);

      if (res.status === 200) {
        dispatch(changeStatus(taskId));
      }
    } catch (e) {
      console.log(e);
      notifyErr('Ошибка', e);
    }
  };
};
export const deleteTaskAction = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await instance.delete(`/tasks/${id}`);
      if (res.status === 204) {
        dispatch(deleteTask(id));
        notifySuc('Задача удалена!');
      }
    } catch (e) {
      notifyErr('Ошибка при удалении!', e);
      console.log(e);
    }
  };
};
