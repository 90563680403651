export const version = '1.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'card'
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
export const ServerString = `${process.env.REACT_APP_SERVER_URL}`;
export const nwString = `${process.env.REACT_APP_NEURAL_NETWORK}`;
