import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITask } from '../types/tasksTypes';

interface ITasksSliceState {
  tasks: ITask[];
}

const initialState: ITasksSliceState = {
  tasks: []
};

export const TaskSlice = createSlice({
  name: 'tasksSlice',
  initialState,
  reducers: {
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    addTask: (state, action) => {
      state.tasks = [action.payload, ...state.tasks];
    },
    changeStatus: (state, action) => {
      state.tasks = state.tasks.map(task => {
        if (task.id === action.payload) {
          return {
            ...task,
            completed: !task.completed
          };
        }
        return task;
      });
    },
    deleteTask: (state, action: PayloadAction<number>) => {
      state.tasks = state.tasks.filter(
        (el: any) => el.id !== Number(action.payload)
      );
    }
  }
});

export default TaskSlice.reducer;
export const { setTasks, addTask, changeStatus, deleteTask } =
  TaskSlice.actions;
