import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { SettingsLazy } from '../pages/user/settings/Settings/Settings.async';
import Registration from 'components/authentication/Registration';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import { LoginLazy } from 'components/authentication/Login/Login.async';
import { LogoutLazy } from 'components/authentication/Logout/Logout.async';
import { ForgetPasswordLazy } from 'components/authentication/ForgetPassword/ForgetPassword.async';
import { PasswordResetLazy } from 'components/authentication/PasswordReset/PasswordReset.async';
import { ConfirmMailLazy } from 'components/authentication/ConfirmMail/ConfirmMail.async';
import { LockScreenLazy } from 'components/authentication/LockScreen/LockScreen.async';
import { SecretKeyLazy } from 'components/authentication/SecretKey/SecretKey.async';
import { SecretKeyCongratulationsLazy } from 'components/authentication/SecretKeyCongratulations/SecretKeyCongratulations.async';
import { EmailConfirmCongratulationsLazy } from 'components/authentication/EmailConfirmCongratulations/EmailConfirmCongratulations.async';
import ErrorLayout from '../layouts/ErrorLayout';
import { IncCorListLazy } from '../modules/Сorrespondence/IncCor/__List/IncCorList.async';
import { DefaultDashboardLazy } from '../dashboards/DefaultDashboard.async';
import { IncCorAddLazy } from '../modules/Сorrespondence/IncCor/__Add/IncCorAdd.async';
import { CorChainPageLazy } from '../modules/Сorrespondence/Сhain/__Page/CorChainPage.async';
import { OutCorListLazy } from '../modules/Сorrespondence/OutCor/__List/OutCorList.async';
import CommonLayout from '../layouts/CommonLayout';
import { OutCorAddLazy } from '../modules/Сorrespondence/OutCor/__Add/OutCorAdd.async';
import { ObjectsListLazy } from '../modules/Objects/ObjectsList/ObjectsList.async';
import { ObjectPageLazy } from '../modules/Objects/ObjectPage/ObjectPage.async';
import { ObjectTuneLazy } from '../modules/Objects/ObjectTune/ObjectTune.async';
import { ObjectAccountingPageLazy } from '../modules/Objects/ObjectAccounting/ObjectAccountingPage.async';
import { TasksPageLazy } from '../modules/Tasks/TasksPage.async';
import { BlanksPageLazy } from '../modules/Blanks/BlanksPage.async';
import { BlankAddLazy } from '../modules/Blanks/__Add/BlankAdd.async';
import { ObjectServicePageLazy } from '../modules/CRM/components/ObjectService/ObjectServicePage.async';
import { ListEquipmentPageLazy } from '../modules/CRM/components/ListEquipment/ListEquipmentPage/ListEquipmentPage.async';
import { AddEquipmentLazy } from '../modules/CRM/components/ListEquipment/AddEquipment/AddEquipment.async';
import { FileShowPageLazy } from 'pages/FileShowPage/FileShowPage.async';
import { NotificationPageLazy } from 'pages/Notification/NotificationPage.async';
import { ProfileLazy } from 'pages/user/profile/Profile.async';
import Error404 from 'pages/Errors/Error404';
import { ListOfEmployeesLazy } from 'modules/Employees/__List/ListOfEmployees.async';
import { AddApplicationsLazy } from 'modules/CRM/components/Applications/AddApplications/AddApplications.async';
import { ListApplicationPageLazy } from 'modules/CRM/components/Applications/ListApplicationsPage/ListApplicationsPage.async';
import { DetailsApplicationLazy } from 'modules/CRM/components/DetailsApplication/DetailsApplication.async';
import { EditApplicationsLazy } from 'modules/CRM/components/Applications/EditApplications/EditApplications.async';

export interface IRoute {
  path: string;
  Component: React.JSXElementConstructor<any>;
  layout: React.JSXElementConstructor<any>;
  title: string;
}

export const privateRoutes = [
  // Dashboards
  {
    path: '/dashboards/default',
    Component: DefaultDashboardLazy,
    layout: MainLayout,
    title: 'СпецСитиСтрой.online'
  },

  // Profile
  {
    path: '/notifications',
    Component: NotificationPageLazy,
    layout: MainLayout,
    title: 'Уведомления'
  },
  {
    path: 'employees/profile/:id',
    Component: ProfileLazy,
    layout: MainLayout,
    title: 'Профиль'
  },
  {
    path: 'employees/profile/',
    Component: ProfileLazy,
    layout: MainLayout,
    title: 'Профиль'
  },

  // Settings

  {
    path: 'user/settings',
    Component: SettingsLazy,
    layout: MainLayout,
    title: 'Настройки'
  },

  // Modules

  // ObjectsList
  {
    path: 'modules/objects',
    Component: ObjectsListLazy,
    layout: MainLayout,
    title: 'Список объектов'
  },
  {
    path: 'modules/objects/:objectId',
    Component: ObjectPageLazy,
    layout: MainLayout,
    title: 'Страница объекта'
  },
  {
    path: '/modules/objects/accounting/*',
    Component: ObjectAccountingPageLazy,
    layout: MainLayout,
    title: 'Учет сотрудников'
  },

  // ObjectTune
  {
    path: '/modules/objects/tune',
    Component: ObjectTuneLazy,
    layout: MainLayout,
    title: 'Настройка объектов'
  },
  // TASKS

  {
    path: '/modules/tasks',
    Component: TasksPageLazy,
    layout: MainLayout,
    title: 'Задачи'
  },

  // Blanks

  {
    path: '/modules/blanks',
    Component: BlanksPageLazy,
    layout: MainLayout,
    title: 'Задачи'
  },
  {
    path: 'modules/fillup_documents/:id',
    Component: BlankAddLazy,
    layout: MainLayout,
    title: 'Создание бланка документов'
  },
  {
    path: 'modules/fillup_documents/',
    Component: BlankAddLazy,
    layout: MainLayout,
    title: 'Создание бланка документов'
  },

  //CRM
  ////!CRM ObjectService
  {
    path: '/modules/object_service',
    Component: ObjectServicePageLazy,
    layout: MainLayout,
    title: 'Объекты обслуживания'
  },
  {
    path: '/modules/list_equipment',
    Component: ListEquipmentPageLazy,
    layout: MainLayout,
    title: 'Список оборудования'
  },

  {
    path: '/modules/add_equipment',
    Component: AddEquipmentLazy,
    layout: MainLayout,
    title: 'Добавление оборудования'
  },

  {
    path: '/modules/list_application',
    Component: ListApplicationPageLazy,
    layout: MainLayout,
    title: 'Список заявок'
  },
  {
    path: '/modules/add_application',
    Component: AddApplicationsLazy,
    layout: MainLayout,
    title: 'Создание заявок'
  },
  {
    path: '/modules/edit_application/:id',
    Component: EditApplicationsLazy,
    layout: MainLayout,
    title: 'Редактирование заявок'
  },
  {
    path: '/modules/details_application/:id',
    Component: DetailsApplicationLazy,
    layout: MainLayout,
    title: 'Детали заявок'
  },

  // OK
  {
    path: 'modules/ok/:depId',
    Component: ListOfEmployeesLazy,
    layout: MainLayout,
    title: 'Справочник сотрудников'
  },

  {
    path: 'modules/ok/',
    Component: ListOfEmployeesLazy,
    layout: MainLayout,
    title: 'Справочник сотрудников'
  },

  // Correspondence
  {
    path: '/modules/OutCorAdd',
    Component: OutCorAddLazy,
    layout: MainLayout,
    title: 'Создание корреспонденции'
  },
  {
    path: '/modules/OutCorAdd/:id',
    Component: OutCorAddLazy,
    layout: MainLayout,
    title: 'Исходящая корреспонденция'
  },

  {
    path: '/modules/outCorList',
    Component: OutCorListLazy,
    layout: MainLayout,
    title: 'Исходящая корреспонденция'
  },

  {
    path: '/modules/incCorList',
    Component: IncCorListLazy,
    layout: MainLayout,
    title: 'Входящая корреспонденция'
  },

  {
    path: 'modules/corespChain/inc/:id',
    Component: CorChainPageLazy,
    layout: MainLayout,
    title: 'Цепочка корреспонденции'
  },

  {
    path: 'modules/corespChain/out/:id',
    Component: CorChainPageLazy,
    layout: MainLayout,
    title: 'Цепочка корреспонденции'
  },

  {
    path: 'modules/incCorAdd/:id',
    Component: IncCorAddLazy,
    layout: MainLayout,
    title: 'Создание корреспонденции'
  },

  {
    path: 'modules/incCorAdd/',
    Component: IncCorAddLazy,
    layout: MainLayout,
    title: 'Создание корреспонденции'
  },

  // Any
  {
    path: '*',
    Component: Error404,
    layout: ErrorLayout,
    title: 'Ошибка 404'
  },

  // Show file page
  {
    path: 's/:id',
    Component: FileShowPageLazy,
    layout: CommonLayout,
    title: 'Просмотр'
  }
];

export const publicRoutes = [
  // Show file page
  {
    path: 's/:id',
    Component: FileShowPageLazy,
    layout: CommonLayout,
    title: 'Просмотр'
  },

  // Authentication
  {
    path: 'authentication/simple/register',
    Component: Registration,
    layout: AuthSimpleLayout,
    title: 'Регистрация'
  },
  {
    path: '',
    Component: LoginLazy,
    layout: AuthSimpleLayout,
    title: 'Авторизация'
  },
  {
    path: 'authentication/simple/logout',
    Component: LogoutLazy,
    layout: AuthSimpleLayout,
    title: 'Выход'
  },
  {
    path: 'authentication/simple/forgot-password',
    Component: ForgetPasswordLazy,
    layout: AuthSimpleLayout,
    title: 'Забыли пароль'
  },
  {
    path: '/users/reset_password/',
    Component: PasswordResetLazy,
    layout: AuthSimpleLayout,
    title: 'Восстановление пароля'
  },
  {
    path: 'authentication/simple/confirm-mail',
    Component: ConfirmMailLazy,
    layout: AuthSimpleLayout,
    title: 'Подтверждение почты'
  },
  {
    path: 'authentication/simple/lock-screen',
    Component: LockScreenLazy,
    layout: AuthSimpleLayout,
    title: 'Авторизация'
  },
  {
    path: 'authentication/simple/secret-key',
    Component: SecretKeyLazy,
    layout: AuthSimpleLayout,
    title: 'Секретный код'
  },
  {
    path: 'authentication/simple/secret-key/congratulations',
    Component: SecretKeyCongratulationsLazy,
    layout: AuthSimpleLayout,
    title: 'Секретный код'
  },
  {
    path: 'authentication/simple/email-confirm/congratulations',
    Component: EmailConfirmCongratulationsLazy,
    layout: AuthSimpleLayout,
    title: 'Подтверждение почты'
  },

  {
    path: '*',
    Component: LoginLazy,
    layout: AuthSimpleLayout,
    title: 'Авторизация'
  }
];
