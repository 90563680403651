import React, { useEffect, useReducer } from 'react';
import AppContext from './context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from './helpers/utils';
import { configReducer } from './falconStore/configReducer';
import useToggleStyle from './hooks/reduxHooks/useToggleStyle';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import useDynamicDocumentTitle from './hooks/useDynamicDocumentTitle';

if (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_URL}`,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'api.speccitystroy.online',
          'speccitystroy.online'
        ]
      })
    ],
    tracesSampleRate: 0.3
  });
}

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };
  useDynamicDocumentTitle();
  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  useEffect(() => {
    // @ts-ignore
    document.querySelector("meta[name='theme-color']").content =
      configState.isDark ? '#0b1727' : '#EDF2FA';
  }, [configState.isDark]);

  const setConfig = (key: any, value: any) => {
    // @ts-ignore
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: getColor('light')
        }}
      />
    );
  }

  return (
    // @ts-ignore
    <AppContext.Provider value={{ config, setConfig }}>
      {children}
    </AppContext.Provider>
  );
};

export default Main;
