import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Col } from 'react-bootstrap';
import { apiAuth } from 'api/api-auth';

const Registration = () => {
  const navigate = useNavigate();
  const [isValidEmail, setIsValidEmail] = useState({
    valid: true,
    errorText: ''
  });
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
  const [isAcceptError, setIsAcceptError] = useState('');
  const [formData, setFormData] = useState<any>({
    email: '',
    password: '',
    isAccepted: false
  });

  // Handler
  const onSubmit = (e: any) => {
    e.preventDefault();

    const data = new FormData();
    data.append('email', formData.email);
    data.append('password', formData.password);
    data.append('agreed', formData.isAccepted);

    // Регистрация обычная
    apiAuth
      .registration(data)
      .then(response => {
        if (response.status === 204) {
          setIsValidEmail({
            valid: false,
            errorText:
              'Ваша почта не найдена в 1С, обратитесь к администратору.'
          });
        } else if (response.status === 201) {
          navigate('/authentication/simple/secret-key/');
          localStorage.setItem('email', formData.email);
        }
      })
      .catch(error => {
        switch (error.response.status) {
          case 409:
            setIsValidEmail({
              valid: false,
              errorText: 'Вы уже зарегистрированы'
            });
            localStorage.setItem('email', formData.email);
            break;
          case 426:
            setIsValidEmail({
              valid: false,
              errorText:
                'Для активации аккаунта введите шестизначный код с почты'
            });
            localStorage.setItem('email', formData.email);
            setTimeout(() => {
              navigate('/authentication/simple/secret-key');
            }, 2500);
            break;
          case 417:
            setIsAcceptError('Примите условия пользовательского соглашения');
            localStorage.setItem('email', formData.email);
            break;
        }
      });
  };

  // Проверка email в базе данных
  const checkEmail = () => {
    apiAuth
      .checkEmail(formData.email)
      .then(() => {
        setIsValidEmail({
          valid: true,
          errorText: ''
        });
      })
      .catch(() => {
        setIsValidEmail({
          valid: false,
          errorText:
            'Для регистрации необходимо ввести свою корпоративную почту'
        });
      });
  };

  // Заполнение инпутов
  const handleFieldChange = (e: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Col className="align-items-center mb-3 d-flex justify-content-between">
        <h5 id="modalLabel">Регистрация</h5>
        <span className="fs--1 text-600 text-end">
          Уже зарегистрированы?
          <Link to="/"> Вход</Link>
        </span>
      </Col>

      {/*EMAIL*/}
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            className={`${!isValidEmail.valid && 'inputNotValid'}`}
            placeholder={'Email адрес'}
            value={formData.email}
            name="email"
            id="email"
            onBlur={() => {
              if (!(formData.email.trim() === '')) {
                checkEmail();
              } else {
                setIsValidEmail({
                  valid: false,
                  errorText: 'Поле обязательно для заполнения!'
                });
              }
            }}
            onChange={e => {
              setIsValidEmail({
                valid: true,
                errorText: ''
              });
              handleFieldChange(e);
            }}
            type="email"
          />
          {!isValidEmail.valid && (
            <p className="text-danger mt-1 fs-12">{isValidEmail.errorText}</p>
          )}
        </Form.Group>

        {/*PASSWORD*/}
        <Form.Group>
          <Form.Control
            placeholder={'Пароль'}
            className={`${isPasswordEmpty && 'inputNotValid'}`}
            value={formData.password}
            name="password"
            onBlur={() => {
              setIsPasswordEmpty(formData.password.trim() === '');
            }}
            onChange={e => {
              setIsPasswordEmpty(false);
              handleFieldChange(e);
            }}
            type="password"
          />
        </Form.Group>
        {isPasswordEmpty && (
          <p className="text-danger mt-1 fs-12">
            Поле обязательно для заполнения!
          </p>
        )}

        {/*CHECK*/}
        <Form.Group className="mb-3 mt-3">
          <Form.Check
            type="checkbox"
            id="acceptCheckbox"
            className="form-check"
          >
            <Form.Check.Input
              type="checkbox"
              className={`${isAcceptError && 'inputNotValidBorder'}`}
              name="isAccepted"
              checked={formData.isAccepted}
              onChange={e => {
                setIsAcceptError('');
                setFormData({
                  ...formData,
                  isAccepted: e.target.checked
                });
              }}
            />
            <p className="form-label fs--1 text-600">
              Нажимая на кнопку “Зарегистрироваться”, я принимаю условия{' '}
              <Link to="/terms">Пользовательского соглашения</Link> и даю своё
              согласие ООО “СпецСитиСтрой” на обработку моей персональной
              информации на условиях, определенных{' '}
              <Link to="/police">Политикой конфиденциальности</Link>.
            </p>
            <Form.Control.Feedback type="invalid">
              Для регистрации необходимо принять условия
            </Form.Control.Feedback>
          </Form.Check>
        </Form.Group>

        {isAcceptError && (
          <p className="text-danger fs-12 m-0 p-0">{isAcceptError}</p>
        )}

        <Form.Group className="mb-3">
          <Button
            className="mt-3 w-100"
            type="submit"
            disabled={!formData.email || !formData.password}
          >
            Зарегистрироваться
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default Registration;
