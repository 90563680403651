import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import Notification from '../../notification/Notification';
import {
  useAppDispatch,
  useTypedSelector
} from '../../../hooks/reduxHooks/redux';
import { INotification } from '../../../reduxStore/types/notificationTypes';
import { readAllNotification } from '../../../reduxStore/actions/notification';
import moment from 'moment';
import Header from 'components/Header/Header';

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newNotifications, setNewNotifications] = useState<INotification[]>([]);
  const [oldNotifications, setOldNotifications] = useState<INotification[]>([]);
  const dispatch = useAppDispatch();
  const { notifications, notificationCounter } = useTypedSelector(
    state => state.notification
  );

  useEffect(() => {
    const newNot: INotification[] = [];
    const oldNot: INotification[] = [];
    notifications.slice(0, 19).forEach((el: INotification) => {
      if (moment(el?.event?.datetime).diff(moment().add(-1, 'days')) < 1) {
        oldNot.push(el);
      } else {
        newNot.push(el);
      }
    });
    setNewNotifications(newNot);
    setOldNotifications(oldNot);
  }, [notifications]);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAsRead = (e: any) => {
    e.preventDefault();
    dispatch(readAllNotification());
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 py-1  nav-link position-relative')}
      >
        {notificationCounter !== 0 && (
          <div className={'notification-counter'}>
            {notificationCounter > 99 ? '99' : notificationCounter}
          </div>
        )}
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-5" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <Header
            title={'Уведомления'}
            classNameCardHeader="ps-0 pb-0"
            classNameCard="shadow-none mb-0"
            TagTitle="h6"
            buttons={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Отметить все
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            {/*Уведомление об отсутствии уведомлений*/}
            {!newNotifications?.length && !oldNotifications?.length && (
              <div className="list-group-title pl-4  p-3">
                Уведомления отсутствуют
              </div>
            )}
            {/*Новые уведомления*/}
            {newNotifications?.length !== 0 && (
              <>
                <div className="list-group-title">Новые</div>{' '}
                {newNotifications?.map(
                  (notification: INotification, i: number) => {
                    return (
                      <ListGroup.Item key={i} onClick={handleToggle}>
                        <Notification notification={notification} flush />
                      </ListGroup.Item>
                    );
                  }
                )}
              </>
            )}
            {/*Устаревшие уведомления*/}
            {oldNotifications?.length !== 0 && (
              <>
                <div className="list-group-title">Устаревшие</div>
                {oldNotifications?.map(
                  (notification: INotification, i: number) => {
                    return (
                      <ListGroup.Item key={i} onClick={handleToggle}>
                        <Notification notification={notification} flush />
                      </ListGroup.Item>
                    );
                  }
                )}
              </>
            )}
          </ListGroup>

          <div
            className="card card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/notifications">
              Показать все
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
