import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FullName, FullNameSingle } from 'helpers/fullname';
import {
  BlanksSliceState,
  IApplicationActivity,
  IApplicationGetArray,
  IApplicationArray,
  IApplication,
  TApplicationType,
  TStatus,
  TValueLabelPhone,
  IApplicationComments,
  IApplicationStatusChangeArray,
  TResponsibleBySystem,
  IApplicationAnalytics
} from 'reduxStore/types/applicationTypes';
import {
  TGetCommonObject,
  TGetCommonSystem
} from 'reduxStore/types/commonTypes';
// import any = jasmine.any;

const initialState: BlanksSliceState = {
  objectApplication: [],
  objectSystemApplication: [],
  applicationType: [],
  applicationEmployees: [],
  applicationEmployeesDetails: [],
  applicationEquipment: [],
  objectManufacturerApplication: [],
  objectCategoryApplication: [],
  applicationEmployeesExecutor: [],
  applicationInitiators: [],
  applicationEmployeesExecutorDetails: [],
  applicationListActivity: [],
  objectSubCategoryApplication: [],
  applicationDispatcher: [],
  applicationMdpoDispatcher: [],
  applicationDispatcherDetails: [],
  applicationMdpoDispatcherDetails: [],
  applicationNumber: [],
  newFieldApplication: {
    object_id: {
      value: 0,
      label: ''
    },
    system: {
      value: 0,
      label: ''
    },
    equipment: {
      value: 0,
      label: ''
    },
    dispatcher: {
      value: 0,
      label: ''
    },
    mdpo_dispatcher: {
      value: 0,
      label: ''
    },

    application_type: {
      value: 0,
      label: '',
      deadline: ''
    },
    employees_option: [],
    employees_executor_option: [],
    // mdpo_dispatcher_option: [],
    application_text: '',
    application_number: '',
    external_date: '',
    deadline: '',
    initiator: '',
    phone: '',
    attachments: [],
    attachments_new: [],
    primary_inspection_act: null,
    primary_inspection_act_new: null,
    visited: false,
    initiator_option: {
      value: '',
      label: ''
    },
    attachmentsCompare: undefined
  },
  objectModelApplication: [],
  newModelApplication: {},
  newSystemApplication: {},
  newManufacturerApplication: {},
  objectSomeApplication: {},
  applicationList: {
    count: 0,
    next: '',
    previous: '',
    results: []
  },
  curAppPage: 1,
  applicationNextPrev: {
    next: '',
    prev: ''
  },

  applicationListStatuses: [],
  applicationIdDetails: {
    id: 0,
    type: {
      id: 0,
      name: '',
      deadline: ''
    },
    system: {
      name: '',
      maintenance_object: '',
      label: '',
      id: ''
    },
    dispatcher: {
      id: 0,
      last_name: '',
      first_name: '',
      patronymic: ''
    },
    mdpo_dispatcher: {
      id: 0,
      last_name: '',
      first_name: '',
      patronymic: ''
    },
    responsible_employees: [],
    direct_executors: [],
    device: {
      id: 0,
      internal_name: '',
      name_factual: '',
      date_created: '',
      serial_number: '',
      project_label: '',
      position_plan: null,
      internal_devices_plan: null,
      passport: null,
      model: 0,
      maintenance_system: 0,
      parent_device: null
    },
    initiator: {
      id: 1,
      name: ''
    },
    text: '',
    number: '',
    deadline: '',
    creation_date: '',
    date_of_start: null,
    due_date: '',
    attachments: [],
    primary_inspection_act: null,
    visited: false,
    buying_till: '',
    completed_at: null,
    status: {
      id: 1,
      name: ''
    }
  },
  newFieldApplicationDetails: {
    object_id: {
      value: '',
      label: ''
    },
    system: {
      value: '',
      label: ''
    },
    equipment: {
      value: '',
      label: ''
    },
    // mdpo_dispatcher: {
    //   value: 0,
    //   label: ''
    // },
    dispatcher: '',
    mdpo_dispatcher: '',
    application_type: {
      value: '',
      label: '',
      deadline: ''
    },
    employees_option: [],
    employees_executor_option: [],
    // mdpo_dispatcher_option: [],
    application_text: '',
    application_number: '',
    external_date: '',
    deadline: '',
    initiator: '',
    phone: '',
    original_file: undefined,
    attachments: undefined,
    attachments_new: undefined,
    primary_inspection_act: null,
    primary_inspection_act_new: null,
    visited: false
  },
  applicationListQP: '',
  applicationEdit: {
    id: 0,
    type: {
      id: 0,
      name: '',
      deadline: ''
    },
    system: {
      name: '',
      maintenance_object: '',
      label: '',
      id: ''
    },
    dispatcher: {
      id: 0,
      last_name: '',
      first_name: '',
      patronymic: ''
    },
    mdpo_dispatcher: {
      id: 0,
      last_name: '',
      first_name: '',
      patronymic: ''
    },
    responsible_employees: [],
    direct_executors: [],
    device: {
      id: 0,
      internal_name: '',
      name_factual: '',
      date_created: '',
      serial_number: '',
      project_label: '',
      position_plan: null,
      internal_devices_plan: null,
      passport: null,
      model: 0,
      maintenance_system: 0,
      parent_device: null
    },
    initiator: {
      id: 1,
      name: '',
      phone: undefined
    },
    text: '',
    number: '',
    creation_date: '',
    deadline: '',
    date_of_start: null,
    due_date: '',
    attachments: [],
    primary_inspection_act: null,
    visited: false,
    buying_till: '',
    completed_at: null,
    status: {
      id: 1,
      name: ''
    }
  },
  applicationEditChange: {
    object_id: {
      value: '',
      label: ''
    },
    system: {
      value: '',
      label: ''
    },
    equipment: {
      value: '',
      label: ''
    },
    dispatcher: {
      value: 0,
      label: ''
    },
    mdpo_dispatcher: {
      value: 0,
      label: ''
    },
    application_type: {
      value: '',
      label: '',
      deadline: ''
    },
    employees_option: [],
    employees_executor_option: [],
    // mdpo_dispatcher_option: [],
    initiator_option: {
      value: '',
      label: '',
      phone: ''
    },
    application_text: '',
    application_number: '',
    external_date: null,
    deadline: null,
    initiator: '',
    phone: '',
    attachments: undefined,
    attachments_new: undefined,
    attachmentsCompare: undefined,
    primary_inspection_act: null,
    primary_inspection_act_new: null,
    visited: false
  },
  patchFieldApplicationEdit: {
    object_id: {
      value: '',
      label: ''
    },
    system: {
      value: '',
      label: ''
    },
    equipment: {
      value: '',
      label: ''
    },
    dispatcher: {
      value: 0,
      label: ''
    },
    mdpo_dispatcher: {
      value: 0,
      label: ''
    },
    application_type: {
      value: '',
      label: '',
      deadline: ''
    },
    employees_option: [],
    employees_executor_option: [],
    // mdpo_dispatcher_option: [],
    initiator_option: {
      value: '',
      label: '',
      phone: ''
    },
    application_text: '',
    application_number: '',
    external_date: null,
    deadline: null,
    initiator: '',
    phone: '',
    attachments: [],
    attachmentsCompare: [],
    attachments_new: [],
    primary_inspection_act: null,
    primary_inspection_act_new: null,
    visited: false
  },
  statusChangeApplicationFields: {
    buying_till: ''
  },
  //@ts-ignore
  newFieldApplicationForPatch: {},
  applicationListComments: [],
  applicationChangeFile: [],
  applicationComment: {
    comment_text: ''
  },
  applicationListUpdated: 0,
  responsibleBySystem: { systemId: -1, employees: [] },
  applicationAnalytics: undefined
};

export const ObjectApplication = createSlice({
  name: 'objectApplicationSlice',
  initialState,
  reducers: {
    setObjectApplication: (
      state,
      action: PayloadAction<TGetCommonObject[]>
    ) => {
      state.objectApplication = action.payload;
    },
    setFeildApplication: (
      state,
      action: PayloadAction<IApplicationGetArray>
    ) => {
      //@ts-ignore
      state.newFieldApplication = action.payload;
    },

    setObjectSystemApplication: (
      state,
      action: PayloadAction<TGetCommonSystem[]>
    ) => {
      state.objectSystemApplication = action.payload;
    },
    setApplicationType: (state, action: PayloadAction<TApplicationType[]>) => {
      state.applicationType = action.payload;
    },
    setApplicationEquipment: (state, action) => {
      state.applicationEquipment = action.payload;
    },
    setApplicationEmployees: (state, action: PayloadAction<any[]>) => {
      state.applicationEmployees = action.payload;
    },
    setApplicationEmployeesDetails: (state, action: PayloadAction<any[]>) => {
      state.applicationEmployeesDetails = action.payload;
    },
    setApplicationEmployeesExecutor: (state, action: PayloadAction<any[]>) => {
      state.applicationEmployeesExecutor = action.payload;
    },

    SetApplicationDispatcher: (state, action) => {
      state.applicationDispatcher = action.payload;
    },
    SetApplicationDispatcherDetails: (state, action: PayloadAction<any[]>) => {
      state.applicationDispatcherDetails = action.payload;
    },

    SetApplicationMdpoDispatcher: (state, action) => {
      state.applicationMdpoDispatcher = action.payload;
    },
    SetApplicationMdpoDispatcherDetails: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.applicationMdpoDispatcherDetails = action.payload;
    },

    setApplicationNumber: (state, action: PayloadAction<any>) => {
      state.applicationNumber = action.payload;
    },

    setApplicationInitiators: (state, action: PayloadAction<any[]>) => {
      state.applicationInitiators = action.payload;
    },
    setApplicationEmployeesExecutorDetails: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.applicationEmployeesExecutorDetails = action.payload;
    },
    delFileById: (state, action: PayloadAction<string>) => {
      state.newFieldApplication.attachments =
        state.newFieldApplication.attachments.filter(
          (el: any) => el.name !== action.payload
        );
      state.newFieldApplication.attachments_new =
        state.newFieldApplication.attachments_new.filter(
          (el: any) => el.name !== action.payload
        );
    },
    delFileEditById: (state, action: PayloadAction<string>) => {
      state.patchFieldApplicationEdit.attachments =
        state.patchFieldApplicationEdit.attachments.filter(
          (el: any) => el.name !== action.payload
        );

      state.patchFieldApplicationEdit.attachments_new =
        state.patchFieldApplicationEdit.attachments_new.filter(
          (el: any) => el.name !== action.payload
        );

      state.newFieldApplicationForPatch =
        state.patchFieldApplicationEdit.attachments;

      state.newFieldApplicationForPatch =
        state.patchFieldApplicationEdit.attachments_new;
    },
    setApplicationList: (state, action: PayloadAction<IApplication>) => {
      state.applicationList = action.payload;
    },
    setApplicationListStatuses: (state, action: PayloadAction<TStatus[]>) => {
      state.applicationListStatuses = action.payload;
    },
    setApplicationIdDetails: (
      state,
      action: PayloadAction<IApplicationGetArray>
    ) => {
      state.applicationIdDetails = action.payload;
    },
    setEditApplication: (
      state,
      action: PayloadAction<IApplicationGetArray>
    ) => {
      state.applicationEdit = action.payload;
    },
    setEditApplicationChange: (
      state,
      action: PayloadAction<IApplicationArray>
    ) => {
      state.applicationEditChange = action.payload;
    },
    setStatusChangeApplicationFields: (
      state,
      action: PayloadAction<IApplicationStatusChangeArray>
    ) => {
      state.statusChangeApplicationFields = action.payload;
    },

    changeFieldApplicationStatusChange: (
      state,
      action: PayloadAction<{ name: string; value: string | null }>
    ) => {
      //@ts-ignore
      state.statusChangeApplicationFields[action.payload.name] =
        action.payload.value;
    },

    changeFeildApplication: (
      state,
      action: PayloadAction<{ name: string; value: string | number | File }>
    ) => {
      if (action.payload.name === 'attachments') {
        state.newFieldApplication.attachments.push(action.payload.value);
      } else if (action.payload.name === 'attachments_new') {
        state.newFieldApplication.attachments_new.push(action.payload.value);
      } else if (action.payload.name === 'primary_inspection_act') {
        //@ts-ignore
        state.newFieldApplication[action.payload.name] =
          action.payload.value === '' ? null : action.payload.value;
      } else {
        //@ts-ignore
        state.newFieldApplication[action.payload.name] = action.payload.value;
      }
    },

    changeFeildApplicationEdit: (
      state,
      action: PayloadAction<{ name: string; value: string | number | File }>
    ) => {
      if (action.payload.name === 'attachments') {
        //@ts-ignore
        state.patchFieldApplicationEdit.attachments.push(action.payload.value);
      } else if (action.payload.name === 'attachments_new') {
        //@ts-ignore
        state.patchFieldApplicationEdit.attachments_new.push(
          action.payload.value
        );
      } else if (action.payload.name === 'primary_inspection_act') {
        //@ts-ignore
        state.patchFieldApplicationEdit[action.payload.name] =
          action.payload.value === '' ? null : action.payload.value;
      } else {
        //@ts-ignore
        state.patchFieldApplicationEdit[action.payload.name] =
          action.payload.value;
      }
    },
    setEditApplicationChangeForPatch: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      //@ts-ignore
      state.newFieldApplicationForPatch[action.payload.name] =
        action.payload.value;
    },

    changeCurAppPage: (state, action: PayloadAction<number>) => {
      state.curAppPage = action.payload;
    },
    setEditApplicationMyStructure: (
      state,
      action: PayloadAction<IApplicationGetArray>
    ) => {
      const responsibleEmployees = FullName(
        action.payload.responsible_employees
      );

      const responsibleExecutors = FullName(action.payload.direct_executors);

      const Dispatcher = FullNameSingle(action.payload.dispatcher);
      const mdpoDispatcher = FullNameSingle(action.payload.mdpo_dispatcher);

      const initiator: TValueLabelPhone = {
        label: action.payload.initiator.name,
        value: action.payload.initiator.id,
        phone: action.payload.initiator.phone
      };

      const myStructure: IApplicationArray = {
        object_id: {
          label: action.payload.system.maintenance_object,
          value: action.payload.system.id
        },
        system: {
          label: action.payload.system.name,
          value: action.payload.system.id
        },
        equipment: {
          label: action.payload.device?.internal_name,
          value: action.payload.device?.id
        },
        application_type: {
          label: action.payload.type.name,
          value: action.payload.type.id,
          deadline: action.payload.deadline
        },
        employees_option: responsibleEmployees,
        employees_executor_option: responsibleExecutors,
        dispatcher: Dispatcher,
        mdpo_dispatcher: mdpoDispatcher,
        // mdpo_dispatcher: {
        //   label: action.payload.mdpo_dispatcher.last_name,
        //   value: action.payload.mdpo_dispatcher.id
        // },

        initiator_option: initiator,
        application_text: action.payload.text,
        application_number: action.payload.number,
        external_date: action.payload.date_of_start,
        deadline: action.payload.deadline,
        initiator: '',
        phone: '',
        attachments: action.payload.attachments,
        attachments_new: [],
        attachmentsCompare: action.payload.attachments,
        primary_inspection_act: action.payload.primary_inspection_act,
        primary_inspection_act_new: null,
        visited: action.payload.visited,
        buying_till: action.payload.buying_till,
        completed_at: action.payload.completed_at
      };
      state.patchFieldApplicationEdit = myStructure;
    },
    changeFeildApplicationDetails: (
      state,
      action: PayloadAction<{ name: string; value: string | number }>
    ) => {
      console.log('action.payload.value', action.payload.value);
      //@ts-ignore
      state.newFieldApplicationDetails[action.payload.name] =
        action.payload.value;
    },
    setApplicationListQP: (state, action: PayloadAction<string>) => {
      state.applicationListQP = action.payload;
    },
    setApplicationActivity: (
      state,
      action: PayloadAction<IApplicationActivity[]>
    ) => {
      state.applicationListActivity = action.payload;
    },
    setApplicationComments: (
      state,
      action: PayloadAction<IApplicationComments[]>
    ) => {
      state.applicationListComments = action.payload;
    },
    changeApplicationFile: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      //@ts-ignore
      state.applicationChangeFile = [
        ...state.applicationChangeFile,
        action.payload.value
      ];
    },
    deleteApplicationFile: (state, action: PayloadAction<{ name: string }>) => {
      //@ts-ignore
      state.applicationChangeFile = state.applicationChangeFile.filter(
        file => file.name === action.payload.name
      );
    },

    changeFeildApplicationComment: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      //@ts-ignore
      state.applicationComment[action.payload.name] = action.payload.value;
    },
    setApplicationListUpdated: (state, action) => {
      state.applicationListUpdated = action.payload;
    },
    setApplicationIdDetailsVisited: state => {
      state.applicationIdDetails.visited = true;
    },
    setApplicationIdDetailsFields: (state, action) => {
      state.applicationIdDetails = {
        ...state.applicationIdDetails,
        ...action.payload
      };
    },
    setResponsibleBySystem: (
      state,
      action: PayloadAction<TResponsibleBySystem>
    ) => {
      state.responsibleBySystem.systemId = action.payload.systemId;
      state.responsibleBySystem.employees = action.payload.employees;
    },
    setApplicationAnalytics: (
      state,
      action: PayloadAction<IApplicationAnalytics | undefined>
    ) => {
      state.applicationAnalytics = action.payload;
    }
  }
});

export const { reducer: objectApplicationReducer } = ObjectApplication;
export const {
  changeCurAppPage,
  setObjectApplication,
  changeFeildApplication,
  changeFeildApplicationComment,
  setObjectSystemApplication,
  setApplicationType,
  delFileById,
  setApplicationListQP,
  setApplicationActivity,
  delFileEditById,
  setApplicationEmployees,
  setApplicationEquipment,
  setApplicationEmployeesExecutor,
  setApplicationEmployeesExecutorDetails,
  setApplicationInitiators,
  setApplicationList,
  setApplicationListStatuses,
  setApplicationIdDetails,
  changeFeildApplicationDetails,
  setApplicationEmployeesDetails,
  SetApplicationDispatcher,
  SetApplicationMdpoDispatcher,
  SetApplicationDispatcherDetails,
  SetApplicationMdpoDispatcherDetails,
  setFeildApplication,
  setEditApplication,
  setEditApplicationChange,
  changeFeildApplicationEdit,
  changeFieldApplicationStatusChange,
  setStatusChangeApplicationFields,
  setEditApplicationChangeForPatch,
  setEditApplicationMyStructure,
  setApplicationComments,
  changeApplicationFile,
  deleteApplicationFile,
  setApplicationListUpdated,
  setApplicationIdDetailsVisited,
  setApplicationIdDetailsFields,
  setResponsibleBySystem,
  setApplicationAnalytics
} = ObjectApplication.actions;
