import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';
import human from '../../assets/img/profile/human.png';

export interface AvatarProps {
  size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  rounded?: string;
  src: string | null;
  name?: any;
  emoji?: string;
  className?: string;
  mediaClass?: string;
  isExact?: boolean;
  icon?: any;
  iconNode?: any;
}

const Avatar: React.FC<AvatarProps> = ({
  size = 'xl',
  rounded,
  src,
  name,
  emoji = '',
  className,
  mediaClass,
  isExact = false,
  icon,
  iconNode
}) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');

  const getAvatar = () => {
    if (src !== undefined) {
      return <img className={mediaClasses} src={src ? src : human} alt="" />;
    }
    if (iconNode) {
      return iconNode(40, 40);
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    );
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

interface AvatarGroupProps {
  children: React.ReactNode;
  className?: string;
  dense?: boolean;
}

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
  children,
  dense,
  className
}) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {children}
    </div>
  );
};

export default Avatar;
