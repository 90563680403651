export const FullName = (array: any[]) => {
  const arrayFullName = array.map((el: any) => {
    return {
      label: `${el.last_name} ${el.first_name} ${
        el.patronymic && el.patronymic
      }`,
      value: el.id
    };
  });
  return arrayFullName;
};

export const FullNameSingle = (value: any) => {
  if (value) {
  return {
    label: `${value.last_name} ${value.first_name} ${value.patronymic}`,
    value: value.id
  };}else {
    return {
      label: '',
      value: ''
    }
  }
}