import instance from './api';

export const apiUsers = {
  getUserData: () => {
    return instance.get(`/employees/profile/settings/`);
  },
  changeUserSettings: (formdata: FormData) => {
    return instance.patch('employees/profile/settings/', formdata);
  },
  getEmployeeData: (employeeId: string) => {
    return instance.get(`employees/profile/${employeeId}/`);
  },
  getUserPermissions: () => {
    return instance.get('users/permissions/');
  }
};
