import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import winter from '../assets/img/profile/winter.png';
import spring from '../assets/img/profile/spring.png';
import summer from '../assets/img/profile/summer.png';
import fall from '../assets/img/profile/fall.png';
import { toast } from 'react-toastify';
import moment from 'moment';

dayjs.extend(duration);

export const notifySuc = (text: string) =>
  toast.success(text, {
    theme: 'light'
  });
export const notifyErr = (text: string, e: any | undefined) =>
  toast.warning(`${e ? JSON.stringify(e?.response?.data) : text}`);

export const lastDigitToWord = (digit: number) => {
  const lastFigure = parseInt(digit.toString().slice(-1));
  if (digit >= 11 && digit < 15) {
    return digit + ' дней';
  } else {
    if (lastFigure === 1) return digit + ' день';
    if (lastFigure > 1 && lastFigure < 5) return digit + ' дня';
    if (lastFigure === 0 || lastFigure >= 5) return digit + ' дней';
  }
};

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (
  key: any,
  defaultValue: any,
  store: any = localStorage
) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key: any, payload: any, store = localStorage) =>
  store.setItem(key, payload);

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const getColor = (name: string, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

//===============================
// Helpers
//===============================
export const capitalize = (str: string) =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const camelize = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = (str: string) => {
  // @ts-ignore
  return str.toLowerCase().replaceAll(' ', '-');
};

export const formattingNumbers = (s: string) => {
  if (!s) {
    return '';
  }
  //+7 xxx xxx-xx-xx
  const x = s.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
  // @ts-ignore
  s = '+7 ' + x[2] + ' ' + x[3] + '-' + x[4] + '-' + x[5];
  return s;
};

export const formatDate = (s: string) => {
  if (!s || s.trim() === '') {
    return '';
  }
  const arr = s.split('-');
  return `${arr[2]}.${arr[1]}.${arr[0]}`;
};

export const yearTime = (vacationStart: number) => {
  switch (vacationStart) {
    case 12:
    case 1:
    case 2:
      return winter;
    case 3:
    case 4:
    case 5:
      return spring;
    case 6:
    case 7:
    case 8:
      return summer;
    case 9:
    case 10:
    case 11:
      return fall;
  }
};

export const getCurDate = () => {
  return moment().format('YYYY-MM-DD');
};

export const isImageName = (filename: string): Boolean => {
  const extension = filename.split('.').pop();
  return extension === 'jpg' || extension === 'jpeg' || extension === 'png';
};
