import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserData } from '../types/userTypes';
import { IEmployee } from '../types/OKTypes';
import { Permission } from 'helpers/permission';

interface IUserSliceState {
  userData: IUserData;
  employeeData: any;
  permissions: Permission[];
}

const initialState: IUserSliceState = {
  userData: {
    status: [
      {
        status: [
          {
            start_date: '',
            end_date: ''
          }
        ],
        type: {
          name: '',
          image: ''
        }
      }
    ],
    settings: {
      hidden_fields: []
    }
  },
  employeeData: {},
  permissions: []
};

export const UserSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserPermissions: (state, action: PayloadAction<Permission[]>) => {
      state.permissions = [...state.permissions, ...action.payload];
    },
    setUserDataToStore: (state, action: PayloadAction<IUserData>) => {
      state.userData = action.payload;
    },
    setProfileImage: (state, action: PayloadAction<string>) => {
      localStorage.setItem('imageProfile', action.payload);
      state.userData.photo = action.payload;
    },
    updateUserSettings: (state, action: PayloadAction<string[]>) => {
      state.userData.settings.hidden_fields = action.payload;
    },
    clearEmployeeData: state => {
      state.employeeData = {};
    },
    setEmployeeDataToStore: (state, action: PayloadAction<IEmployee[]>) => {
      state.employeeData = action.payload;
    }
  }
});

export default UserSlice.reducer;
export const {
  setUserPermissions,
  setUserDataToStore,
  setProfileImage,
  updateUserSettings,
  setEmployeeDataToStore,
  clearEmployeeData
} = UserSlice.actions;
