import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from '../../assets/icons/logo_scs_online.svg';
import text_online_logo from '../../assets/icons/text_online_logo.svg';
import Flex from './Flex';

interface LogoProps {
  at?: 'navbar-vertical' | 'navbar-top' | 'auth';
  width?: number;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  at = 'auth',
  width = 80,
  className,
  ...rest
}) => {
  return (
    <Link
      to="/"
      className={classNames(
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <Flex>
        <div
          className={classNames(
            'd-flex',
            {
              'align-items-center py-2': at === 'navbar-vertical',
              'align-items-center': at === 'navbar-top',
              'flex-center fw-bolder fs-5': at === 'auth'
            },
            className
          )}
        >
          <img src={logo} alt="Logo" width={width} />
        </div>
        <div
          className={classNames(
            'd-flex',
            {
              'align-items-center text-online-logo-vertical':
                at === 'navbar-vertical',
              'align-items-center text-online-logo-top d-none d-sm-block':
                at === 'navbar-top',
              'd-none': at === 'auth'
            },
            className
          )}
        >
          <img src={text_online_logo} alt="Logo_text" width={120} />
        </div>
      </Flex>
    </Link>
  );
};

export default Logo;
