// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 981px) {
  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-title {
    max-width: 450px;
  }
}
@media screen and (max-width: 980px) {
  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right-title {
    max-width: 250px;
  }
}
@media screen and (max-width: 575px) {
  .container-title {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .right-title {
    max-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE;IACE,aAAA;IACA,8BAAA;IACA,mBAAA;EACF;EACA;IACE,gBAAA;EACF;AACF;AACA;EACE;IACE,aAAA;IACA,8BAAA;IACA,mBAAA;EACF;EACA;IACE,gBAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;IACA,kBAAA;IACA,sBAAA;EAAF;EAEA;IACE,eAAA;EAAF;AACF","sourcesContent":["@media screen and (min-width: 981px) {\n  .container-title {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .right-title {\n    max-width: 450px;\n  }\n}\n@media screen and (max-width: 980px) {\n  .container-title {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .right-title {\n    max-width: 250px;\n  }\n}\n\n@media screen and (max-width: 575px) {\n  .container-title {\n    display: flex;\n    align-items: start;\n    flex-direction: column;\n  }\n  .right-title {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
