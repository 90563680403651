import React, { useState } from 'react';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import { Alert } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { useTypedSelector } from '../hooks/reduxHooks/redux';
import { v4 } from 'uuid';
import useTitle from '../hooks/useTitle';

interface MainLayoutProps {
  children: React.ReactNode;
  title?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, title }) => {
  const [isOnline, setIsOnline] = useState(true);
  const urgent = useTypedSelector(
    state => state.notification.urgentNotification
  );
  useTitle(title, true);

  window.addEventListener('online', () => setIsOnline(true));
  window.addEventListener('offline', () => {
    setIsOnline(false);
    window.scrollTo(0, 0);
  });

  return (
    <div className={'container-fluid'}>
      <NavbarVertical />
      <div className={'content'}>
        <NavbarTop />
        {!isOnline && (
          <Alert variant={'danger'}>Проблема с подключением к интернету!</Alert>
        )}
        {urgent !== null && (
          <Alert key={v4()} variant={'warning'} className={'p-3 mb-3'}>
            {urgent?.message?.text}
          </Alert>
        )}
        {/*------ Main Routes ------*/}
        {children}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={'light'}
        />
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
