import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

export interface IReceiverType {
  Ident?: string;
  id?: string | null;
  type: string | null;
  email_type?: string | null;
  employee?: number | null;
  contractor?: number | null;
  name?: string | null;
  email?: string | null;
  track_number?: string | null;
  comment?: string | null;
  label?: string | null;
}

interface IReceiversSliceState {
  receiversArr: IReceiverType[];
  sssReceivers: IReceiverType[];
  additionalReceivers: IReceiverType[];
  formOfSendWords: string[];
}

const initialState: IReceiversSliceState = {
  receiversArr: [],
  sssReceivers: [],
  additionalReceivers: [],
  formOfSendWords: []
};

export const ReceiversSlice = createSlice({
  name: 'receiversSlice',
  initialState,
  reducers: {
    //Очистка слайса
    clearReceivers: () => {
      return initialState;
    },
    // Основные получатели
    setMainReceiver: (state, action: PayloadAction<IReceiverType[]>) => {
      state.receiversArr = action.payload
        .filter(el => el.type === 'Основной' && !el.email_type)
        .map((el: IReceiverType) => {
          return {
            ...el,
            id: el?.id,
            type: 'Основной',
            employee: el?.employee ? el.employee : null,
            contractor: el?.contractor ? el.contractor : null,
            name: el?.name || '',
            email: el?.email || '',
            label: el?.name,
            value: el?.id
          };
        });
    },
    addMainReceiver: (state, action) => {
      const receiverInStore = state.receiversArr.find((elem: any) => {
        return (
          elem?.name === action.payload?.name &&
          elem?.type === action.payload.type
        );
      });
      if (!receiverInStore) {
        state.receiversArr = [...state.receiversArr, action.payload];
      }
    },
    delAllMainReceivers: state => {
      state.receiversArr = [];
    },
    delMainReceiverById: (state, action) => {
      state.receiversArr = state.receiversArr.filter((el: any) => {
        return el.id !== action.payload;
      });
    },
    // Дополнительные получатели (Нарочно, Почта и т.д.)
    setAdditionalReceiver: (state, action: PayloadAction<IReceiverType[]>) => {
      state.additionalReceivers = action.payload
        .filter(el => {
          return (
            el.type !== 'Основной' &&
            el.type !== 'Пересылка' &&
            el.email_type === null
          );
        })
        .map((el: IReceiverType) => {
          return {
            ...el,
            label: el.name
          };
        });
    },
    addAdditionalReceiver: (state, action: PayloadAction<IReceiverType>) => {
      let receiverInStore;
      if (action.payload.type === 'Почта сотрудника') {
        receiverInStore = state.additionalReceivers.find(
          (elem: IReceiverType) => {
            return (
              elem?.name === action.payload?.name &&
              elem?.type === action.payload.type
            );
          }
        );
      } else {
        receiverInStore = state.additionalReceivers.find(
          (elem: IReceiverType) => {
            return elem?.type === action.payload.type;
          }
        );
      }

      if (!receiverInStore) {
        state.additionalReceivers = [
          ...state.additionalReceivers,
          action.payload
        ];
      } else {
        state.additionalReceivers = state.additionalReceivers.map(
          (el: IReceiverType) => {
            if (el.type === action.payload.type) {
              return {
                ...el,
                ...action.payload
              };
            } else {
              return el;
            }
          }
        );
      }
    },
    delAllAdditionReceiver: state => {
      state.additionalReceivers = [];
    },
    delAdditionalReceiverByType: (state, action: PayloadAction<string>) => {
      state.additionalReceivers = state.additionalReceivers.filter(
        (el: IReceiverType) => {
          return el.type !== action.payload;
        }
      );
    },
    delAdditionalReceiverById: (
      state,
      action: PayloadAction<IReceiverType>
    ) => {
      if (action.payload.type === 'Почта ССС') {
        state.additionalReceivers = state.additionalReceivers.filter(el => {
          return el.type !== 'Почта ССС';
        });
      } else {
        state.additionalReceivers = state.additionalReceivers.filter(el => {
          return el.id !== action.payload.id;
        });
      }
    },
    // Формы отправки
    delFormOfSendWord: (state, action) => {
      state.formOfSendWords = state.formOfSendWords.filter(
        el => el !== action.payload
      );
    },
    delAllFormOfSendWord: state => {
      state.formOfSendWords = [];
    },
    addFormOfSendWord: (state, action: PayloadAction<string>) => {
      state.formOfSendWords = [...state.formOfSendWords, action.payload];
    },
    setFormOfSendWord: (state, action: PayloadAction<IReceiverType[]>) => {
      let formOfSend = action.payload
        .filter((el: IReceiverType) => {
          return el.type !== 'Основной' && el.type !== 'Пересылка';
        })
        .map((el: IReceiverType): any => {
          if (el.type === 'Электронная почта') {
            return 'Почта ССС';
          } else {
            return el.type;
          }
        });
      formOfSend = formOfSend.filter((item: string, pos: number) => {
        return formOfSend.indexOf(item) === pos;
      });
      state.formOfSendWords = formOfSend;
    },
    // SSS-Receivers
    setSSSReceiver: (state, action: PayloadAction<IReceiverType[]>) => {
      state.sssReceivers = action.payload
        .filter((el: IReceiverType) => {
          return el.email_type !== null;
        })
        .map((el: IReceiverType) => {
          return {
            ...el,
            Ident: v4(),
            label: el.name
          };
        });
    },
    delSSSReceiver: (state, action) => {
      state.sssReceivers = state.sssReceivers.filter((el: IReceiverType) => {
        return el.Ident !== action.payload;
      });
    },
    dellAllSSSReceiver: state => {
      state.sssReceivers = [];
    },
    addOrChangeSSS: (state, action) => {
      const receiverInStore = state.sssReceivers.find((elem: IReceiverType) => {
        return elem?.Ident === action.payload?.Ident;
      });

      if (!receiverInStore) {
        state.sssReceivers = [...state.sssReceivers, action.payload];
      } else {
        state.sssReceivers = state.sssReceivers.map((el: IReceiverType) => {
          if (el?.Ident === action.payload?.Ident) {
            return {
              ...el,
              ...action.payload
            };
          } else {
            return el;
          }
        });
      }
    }
  }
});

export default ReceiversSlice.reducer;
export const {
  setSSSReceiver,
  addFormOfSendWord,
  delAllFormOfSendWord,
  delFormOfSendWord,
  setMainReceiver,
  setAdditionalReceiver,
  delAllMainReceivers,
  delAdditionalReceiverById,
  addOrChangeSSS,
  setFormOfSendWord,
  clearReceivers,
  delSSSReceiver,
  delMainReceiverById,
  addAdditionalReceiver,
  delAdditionalReceiverByType,
  dellAllSSSReceiver,
  delAllAdditionReceiver,
  addMainReceiver
} = ReceiversSlice.actions;
