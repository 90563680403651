import { useEffect } from 'react';

const useTitle = (title: string = '', resetOnUnmount = true) => {
  useEffect(() => {
    if (!resetOnUnmount) return;
    let initialTitle = document.title;

    return () => {
      document.title = initialTitle;
    };
  }, [resetOnUnmount]);

  useEffect(() => {
    document.title = title;
  }, [title]);
};

export default useTitle;
