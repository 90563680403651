import axios from 'axios';
import ky from 'ky';
import { ServerString } from '../config';
import { setIsLoggedIn } from 'reduxStore/slices/authSlice';
import { store } from '../index';

const token = localStorage.getItem('tokenAccess');

export const instanceWithoutToken = axios.create({
  baseURL: ServerString
});
export const instanceWithoutTokenKy = ky.create({
  prefixUrl: ServerString
});
const instance = axios.create({
  baseURL: ServerString,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

export default instance;

export const instanceKy = ky.create({
  prefixUrl: ServerString,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

instance.interceptors.request.use(config => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    'tokenAccess'
  )}`;
  return config;
});

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const originalRequest = err.config;
    if (err?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch(err => {
            localStorage.removeItem('tokenAccess');
            localStorage.removeItem('tokenRefresh');
            window.location.pathname = '/';
            store.dispatch(setIsLoggedIn(false));
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axios
          .post(`${ServerString}/api/token/refresh/`, {
            refresh: localStorage.getItem('tokenRefresh')
          })
          .then(({ data }) => {
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + data.access;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
            localStorage.setItem('tokenAccess', data.access);
            localStorage.setItem('tokenRefresh', data.refresh);
            processQueue(null, data.access);
            store.dispatch(setIsLoggedIn(true));
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            localStorage.removeItem('tokenAccess');
            localStorage.removeItem('tokenRefresh');
            window.location.pathname = '/';
            store.dispatch(setIsLoggedIn(false));
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);
