import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Selected {
  selected: string;
}

export const getSelected = (state: Selected) => state.selected ?? '';

const SelectedSlice = createSlice({
  name: 'SelectedSlice',
  initialState: {
    selected: ''
  } as Selected,
  reducers: {
    setSelected: (state, action: PayloadAction<Selected>) => {
      state.selected = action.payload.selected;
    }
  }
});

export const { reducer: selectedReducer, actions: selectedActions } =
  SelectedSlice;
