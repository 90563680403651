import instance from './api';
import { ServerString } from '../config';

export const apiNotifications = {
  readAllNotifications: () => {
    return instance.patch(`${ServerString}/notifications/read_all/`);
  },
  readOneNotification: (notificationId: number) => {
    return instance.patch(
      `${ServerString}/notifications/${notificationId}/visited/`
    );
  }
};
