export const dashboardRoutes = {
  label: 'Дашборды',
  labelDisable: true,
  children: [
    {
      name: 'Дашборды',
      active: true,
      isOpen: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Главный',
          to: '/',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export const appRoutes = {
  label: 'Модули',
  children: [
    {
      name: 'Сотрудники',
      icon: 'user-friends',
      to: '/modules/ok',
      active: true
    },
    {
      name: 'Корреспонденция',
      icon: 'envelope',
      active: true,
      isOpen: true,
      children: [
        {
          name: 'Входящая',
          to: '/modules/incCorList',
          exact: true,
          active: false
        },
        {
          name: 'Исходящая',
          to: '/modules/outCorList',
          exact: true,
          active: false
        }
      ]
    },
    {
      name: 'Объекты',
      icon: 'map',
      active: true,
      isOpen: true,
      children: [
        {
          name: 'Список',
          to: '/modules/objects',
          exact: true,
          active: false
        },
        {
          name: 'Учет',
          to: '/modules/objects/accounting/employee',
          exact: true,
          active: false,
          labelDisabledPermission:
            'objects.construction__view_checkins_aggregate'
        },
        {
          name: 'Настройки',
          to: '/modules/objects/tune',
          exact: true,
          active: false,
          labelDisabledPermission: 'objects.change_objectpermissiontune'
        }
      ]
    },
    {
      name: 'Задачи',
      icon: 'tasks',
      to: '/modules/tasks',
      active: true
    },
    {
      name: 'Бланки документов',
      icon: 'fa-file-signature',
      to: '/modules/blanks',
      active: true
    },
    {
      name: 'CRM',
      icon: 'fa-file-signature',
      active: true,
      isOpen: true,
      children: [
        {
          name: 'Cписок заявок',
          to: '/modules/list_application',
          exact: true,
          active: false
        }
      ]
    }
  ]
};

export const profileRoutes = {
  label: 'Профиль',
  labelDisable: false,
  children: [
    {
      name: 'Личный кабинет',
      active: true,
      isOpen: true,
      icon: 'user',
      children: [
        {
          name: 'Профиль',
          to: '/employees/profile/',
          exact: true,
          active: true
        },
        {
          name: 'Уведомления',
          to: '/notifications',
          exact: true,
          active: true
        },
        {
          name: 'Настройки',
          to: '/user/settings',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [dashboardRoutes, appRoutes, profileRoutes];
