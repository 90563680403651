import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  employeeAccountingWidgetType,
  IObjectSettings,
  IObjectType,
  IWorkingDoc,
  ObjectTuneDormitoriesProps,
  ObjectTuneSaveFormProps,
  ObjectTuneObjectsTypesProps,
  ObjectTuneFoodProvidersProps,
  IObjectTaskList
} from '../types/objectTypes';

interface IObjectSliceState {
  objectList: IObjectType[];
  workingDocumentation: IWorkingDoc[] | [];
  filteredObjectList: IObjectType[];
  currentObject: any;
  employeeAccountingWidget: employeeAccountingWidgetType | null;
  objectEmployeesTable: {
    aggregate: any[];
    summary: any;
  };
  objectNutritionTable: any[];
  nutritionTableSummaryData: any[];
  nutritionObjectData: any[];
  objectSettings: IObjectSettings;
  correspondence: {
    incoming_correspondence: any[];
    outgoing_correspondence: any[];
  } | null;
  objectTypFilter: string;
  objectTunePermissions: any;
  objectTune: any;
  ObjectTuneObjectsTypes: ObjectTuneObjectsTypesProps[];
  objectTuneFoodProviders: ObjectTuneFoodProvidersProps[];
  objectTuneDormitories: ObjectTuneDormitoriesProps[];
  objectTuneSearchObject: any;
  objectTuneSearchEmployees: any;
  objectTuneSelectActions: any;
  objectTuneProgramTypes: ObjectTuneObjectsTypesProps[];
  ObjectTuneSaveForm: ObjectTuneSaveFormProps;
  ObjectTuneIdForEdit: number | null;
  objectTuneById: any;
  objectTaskList: IObjectTaskList[];
}

const initialState: IObjectSliceState = {
  objectList: [],
  workingDocumentation: [],
  filteredObjectList: [],
  currentObject: {},
  employeeAccountingWidget: null,
  objectEmployeesTable: {
    aggregate: [],
    summary: {}
  },
  objectNutritionTable: [],
  nutritionTableSummaryData: [],
  nutritionObjectData: [],
  objectSettings: {
    base_folder: '',
    name: '',
    short_name: '',
    SCS_code: '',
    customer_code: '',
    CRM_name: '',
    start_date_planned: '',
    end_date_planned: ''
  },
  correspondence: null,
  objectTypFilter: '',
  objectTunePermissions: null,
  objectTune: null,
  ObjectTuneObjectsTypes: [],
  objectTuneFoodProviders: [],
  objectTuneDormitories: [],
  objectTuneSearchObject: [],
  objectTuneSearchEmployees: [],
  objectTuneSelectActions: [],
  objectTuneProgramTypes: [],
  ObjectTuneSaveForm: {
    employees: [],
    project_objects: [],
    program_types: [],
    object_permissions: []
  },
  ObjectTuneIdForEdit: null,
  objectTuneById: null,
  objectTaskList: []
};

export const ObjectSlice = createSlice({
  name: 'ObjectSlice',
  initialState,
  reducers: {
    // Список объектов
    setObjectList: (state, action: PayloadAction<IObjectType[]>) => {
      state.objectList = action.payload;
    },
    setFilteredObjectList: (state, action: PayloadAction<IObjectType[]>) => {
      state.filteredObjectList = action.payload;
    },
    setFilteredObjectType: (state, action) => {
      state.objectTypFilter = action.payload;
    },
    // Страница объекта
    setCurObjInfo: (state, action) => {
      state.currentObject = action.payload;
    },
    // Страница настройки прав доступа по объектам
    setObjectTune: (state, action: PayloadAction) => {
      state.objectTune = action.payload;
    },
    setObjectTunePermissions: (state, action: PayloadAction) => {
      state.objectTunePermissions = action.payload;
    },
    setObjectTuneObjectsTypes: (state, action) => {
      state.ObjectTuneObjectsTypes = action.payload;
    },
    setObjectTuneFoodProviders: (state, action) => {
      state.objectTuneFoodProviders = action.payload;
    },
    setObjectTuneDormitories: (state, action) => {
      state.objectTuneDormitories = action.payload;
    },
    setObjectTuneSearchObject: (state, action) => {
      state.objectTuneSearchObject = action.payload;
    },
    setObjectTuneSearchEmployees: (state, action) => {
      state.objectTuneSearchEmployees = action.payload;
    },
    setObjectTuneSelectActions: (state, action) => {
      state.objectTuneSelectActions = action.payload;
    },
    setObjectTuneProgramTypes: (state, action) => {
      state.objectTuneProgramTypes = action.payload;
    },
    setObjectTuneSaveForm: (state, action) => {
      state.ObjectTuneSaveForm = action.payload;
    },
    setObjectTuneIdForEdit: (state, action) => {
      state.ObjectTuneIdForEdit = action.payload;
    },
    setObjectTunById: (state, action: PayloadAction) => {
      state.objectTuneById = action.payload;
    },

    // Список учета сотрудников
    objectEmployeesTable: (state, action) => {
      state.objectEmployeesTable = action.payload;
    },
    setEmployeeAccountingInfo: (
      state,
      action: PayloadAction<employeeAccountingWidgetType | null>
    ) => {
      state.employeeAccountingWidget = action.payload;
    },
    // Рабочая документация
    setWorkingDocumentation: (state, action: PayloadAction<IWorkingDoc[]>) => {
      state.workingDocumentation = action.payload;
    },
    // Таблица питания
    setNutritionTableData: (state, action) => {
      state.objectNutritionTable = action.payload;
    },
    setNutritionObjectData: (state, action) => {
      state.nutritionObjectData = action.payload;
    },
    setNutritionTableSummaryData: (state, action) => {
      state.nutritionTableSummaryData = action.payload;
    },
    setObjectSettingsData: (state, action: PayloadAction<IObjectSettings>) => {
      state.objectSettings = {
        ...action.payload
      };
    },
    changeObjectSettingsData: (
      state,
      action: PayloadAction<{ id: string; value: string }>
    ) => {
      state.objectSettings = {
        ...state.objectSettings,
        [action.payload.id]: action.payload.value
      };
    },
    setCorrespondence: (state, action) => {
      state.correspondence = action.payload;
    },
    setObjectTaskList: (state, action) => {
      state.objectTaskList = action.payload;
    }
  }
});

export default ObjectSlice.reducer;
export const {
  setObjectList,
  setCorrespondence,
  changeObjectSettingsData,
  objectEmployeesTable,
  setCurObjInfo,
  setFilteredObjectList,
  setEmployeeAccountingInfo,
  setWorkingDocumentation,
  setNutritionTableData,
  setNutritionTableSummaryData,
  setNutritionObjectData,
  setObjectSettingsData,
  setFilteredObjectType,
  setObjectTune,
  setObjectTunePermissions,
  setObjectTuneObjectsTypes,
  setObjectTuneFoodProviders,
  setObjectTuneDormitories,
  setObjectTuneSearchObject,
  setObjectTuneSearchEmployees,
  setObjectTuneSelectActions,
  setObjectTuneSaveForm,
  setObjectTuneProgramTypes,
  setObjectTuneIdForEdit,
  setObjectTunById,
  setObjectTaskList
} = ObjectSlice.actions;
