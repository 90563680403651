import React from 'react';
import useTitle from '../hooks/useTitle';

interface CommonLayoutProps {
  children: React.ReactNode;
  title: string;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ children, title }) => {
  useTitle(title, true);
  return <>{children}</>;
};

export default CommonLayout;
