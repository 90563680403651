import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BlankProps, BlankNewProps } from 'reduxStore/types/blanksTypes';

interface BlanksSliceState {
  blanks: BlankProps[];
  newBlank: BlankProps;
  changeInputBlank: BlankNewProps | any;
  searchBlanks: BlankProps[];
  searchBlankValue: string;
  departmentChips: null | number;
}

const initialState: BlanksSliceState = {
  blanks: [],
  newBlank: {
    id: 0,
    name: '',
    file: '',
    file_name: null,
    date_edited: null,
    date_created: '',
    file_text: null,
    extra_upload: [],
    extra_upload_new: [],
    department: null
  },
  changeInputBlank: {
    file: []
  },
  searchBlanks: [],
  searchBlankValue: '',
  departmentChips: null
};

export const BlankSlice = createSlice({
  name: 'blanksSlice',
  initialState,
  reducers: {
    setBlank: (state, action) => {
      state.blanks = action.payload;
    },
    setBlankEditData: (state, action) => {
      state.newBlank = action.payload;
    },
    addBlank: (state, action) => {
      state.blanks = [action.payload, ...state.blanks];
    },
    changeBlank: (state, action) => {
      state.blanks = action.payload;
    },
    deleteBlank: (state, action: PayloadAction<number>) => {
      state.blanks = state.blanks.filter(
        (el: BlankProps) => el.id !== Number(action.payload)
      );
    },
    setBlankExtraFile: (state, action: PayloadAction<File>) => {
      state.newBlank.extra_upload = [action.payload];
      state.changeInputBlank.file = [...state.newBlank.extra_upload];
    },
    setBlankNewExtraFile: (state, action: PayloadAction<File>) => {
      state.newBlank.extra_upload_new = [action.payload];
      state.changeInputBlank.file = [...state.newBlank.extra_upload_new];
    },
    changeInputValue: (
      state,
      action: PayloadAction<{ name: any; value: any }>
    ) => {
      if (action.payload.name === 'extra_upload') {
        state.newBlank.extra_upload.push(action.payload.value);
      } else if (action.payload.name === 'extra_upload_new') {
        state.newBlank.extra_upload_new.push(action.payload.value);
      } else {
        state.changeInputBlank[action.payload.name] = action.payload.value;
      }
    },
    clearBlank: state => {
      state.newBlank = initialState.newBlank;
      state.changeInputBlank = {
        file: []
      };
    },
    delExtraFileBlankById: (state, action: PayloadAction<string>) => {
      state.newBlank.extra_upload = state.newBlank.extra_upload.filter(
        (el: any) => el.name !== action.payload
      );
      state.newBlank.extra_upload_new = state.newBlank.extra_upload_new.filter(
        (el: any) => el.name !== action.payload
      );
      state.changeInputBlank.file = state.newBlank.extra_upload_new.filter(
        (el: any) => el.name !== action.payload
      );
    },
    setBlankFiltered: (state, action) => {
      state.searchBlanks = action.payload;
    },
    setSearchBlankValue: (state, action) => {
      state.searchBlankValue = action.payload;
    },
    setClickBlankDepartment: (state, action) => {
      state.departmentChips = action.payload;
    }
  }
});

export const { reducer: blankSliceReducer } = BlankSlice;
export const {
  setBlank,
  addBlank,
  changeBlank,
  deleteBlank,
  setBlankEditData,
  clearBlank,
  changeInputValue,
  delExtraFileBlankById,
  setBlankExtraFile,
  setBlankNewExtraFile,
  setBlankFiltered,
  setSearchBlankValue,
  setClickBlankDepartment
} = BlankSlice.actions;
