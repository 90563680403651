import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EmplOnObjectType,
  FoodProviderType,
  IDormitoryType
} from '../types/employeeAccountingType';

interface IEmployeeAccountingState {
  emplOnObject: EmplOnObjectType[];
  food_providers_list: FoodProviderType[] | [];
  main_food_provider: FoodProviderType | null;
  dormitories_list: IDormitoryType[] | [];
  object_registry: any[];
  dormitories_table: any;
  report_table: any;
  date: any;
  employeeAccountingDataPayload: any[];
  employeeAccountingDataStatus: number;
}

const initialState: IEmployeeAccountingState = {
  emplOnObject: [],
  food_providers_list: [],
  main_food_provider: null,
  dormitories_list: [],
  dormitories_table: [],
  report_table: [],
  object_registry: [],
  date: {},
  employeeAccountingDataPayload: [],
  employeeAccountingDataStatus: 0
};

export const EmployeeAccountingSlice = createSlice({
  name: 'employeeAccountingSlice',
  initialState,
  reducers: {
    setDormitoriesTable: (state, action) => {
      state.dormitories_table = action.payload;
    },
    setEmployeeOnObject: (
      state,
      action: PayloadAction<EmplOnObjectType[] | []>
    ) => {
      state.emplOnObject = action.payload;
    },
    setMainFoodProvider: (state, action: PayloadAction<FoodProviderType>) => {
      state.main_food_provider = action.payload;
    },
    setFoodProviderList: (state, action: PayloadAction<FoodProviderType[]>) => {
      state.food_providers_list = action.payload;
    },
    setDormitoriesList: (
      state,
      action: PayloadAction<IDormitoryType[] | []>
    ) => {
      state.dormitories_list = action.payload;
    },
    setEmployeeAccountingData: (state, action) => {
      state.employeeAccountingDataPayload = action.payload;
    },
    setEmployeeAccountingDataStatus: (state, action: PayloadAction<number>) => {
      state.employeeAccountingDataStatus = action.payload;
    },
    setReportTableData: (state, action) => {
      state.report_table = action.payload;
    },
    setObjectRegistry: (state, action) => {
      state.object_registry = action.payload.reverse();
    },
    changeNewDataInputValue: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.date[action.payload.name] = action.payload.value;
    }
  }
});

export const {
  setReportTableData,
  setEmployeeOnObject,
  setDormitoriesList,
  setFoodProviderList,
  setMainFoodProvider,
  setDormitoriesTable,
  setObjectRegistry,
  changeNewDataInputValue,
  setEmployeeAccountingData,
  setEmployeeAccountingDataStatus
} = EmployeeAccountingSlice.actions;
export default EmployeeAccountingSlice.reducer;
