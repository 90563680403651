import { AppDispatch } from '../index';
import { apiOK } from '../../api/api-ok';
import {
  setAllDepartments,
  setSubdivisions,
  setEmployees,
  setCurDepartment
} from '../slices/okSlice';

//Thunk
export const getAllDepartments = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiOK.getAllDep();

      if (res.status === 200) {
        dispatch(setAllDepartments(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getSubdivisions = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiOK.getSubdivisions();
      if (res.status === 200) {
        dispatch(setSubdivisions(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllEmployees = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiOK.getStartedEmployees();
      if (res.status === 200) {
        dispatch(setEmployees(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getDepartmentInfo = (depId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiOK.getDepartmentInfo(depId);
      if (res.status === 200) {
        dispatch(
          setCurDepartment({
            name: res.data.name,
            alternative_name: res.data.subdivision.alternative_name,
            id: res.data.id,
            icon: res.data.icon
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getEmployeesFromDep = (depId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiOK.getEmployeesFromDep(depId);
      if (res.status === 200) {
        dispatch(setEmployees(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};
