import { apiNotifications } from 'api/api-notification';
import { AppDispatch } from '../index';
import { markAsReadAll, markAsReadOne } from '../slices/notificationSlice';

export const readAllNotification = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiNotifications.readAllNotifications();

      if (res.status === 200) {
        dispatch(markAsReadAll());
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const readOneNotification = (id: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiNotifications.readOneNotification(id);

      if (res.status === 200) {
        dispatch(markAsReadOne(id));
      }
    } catch (e) {
      console.log(e);
    }
  };
};
