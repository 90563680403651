import { useEffect, useState } from 'react';
import { useTypedSelector } from './reduxHooks/redux';

const useDynamicDocumentTitle = () => {
  const [onblur, setOnBlur] = useState(false);
  const notCounter = useTypedSelector(
    state => state.notification.notificationCounter
  );

  useEffect(() => {
    const fav = document.getElementById('favicon');
    if (onblur && notCounter !== 0) {
      document.title = `(${notCounter}) Новое уведомление`;
      // @ts-ignore
      fav.href = '/favNot.ico';
    } else {
      // @ts-ignore
      fav.href = '/favicon.ico';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onblur]);

  useEffect(() => {
    window.addEventListener('blur', () => {
      setOnBlur(true);
    });
    window.addEventListener('focus', () => {
      setOnBlur(false);
    });

    return () => {
      window.removeEventListener('blur', () => {
        setOnBlur(true);
      });
      window.removeEventListener('focus', () => {
        setOnBlur(false);
      });
    };
  }, []);
};
export default useDynamicDocumentTitle;
