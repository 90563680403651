import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from '../../../context/Context';
import Logo from '../../common/Logo';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext<any>(AppContext);
  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = useCallback(() => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  }, [navbarCollapsed, navbarPosition, setConfig, showBurgerMenu]);

  const setDropShadow = useCallback(() => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, [setDropShadow]);

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
        'navbar-glass-shadow': showDropShadow
      })}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>
      <Logo at="navbar-top" width={60.5} />

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default memo(NavbarTop);
