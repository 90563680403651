import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './slices/authSlice';
import CorrespondenceSlice from './slices/correspSlice';
import UserSlice from './slices/userSlice';
import OKSlice from './slices/okSlice';
import NotificationSlice from './slices/notificationSlice';
import OutCorSlice from './slices/outCorSlice';
import ReceiversSlice from './slices/receiversSlice';
import ObjectSlice from './slices/objectSlice';
import EmployeeAccountingSlice from './slices/employeeAccountingSlice';
import TaskSlice from './slices/tasksSlice';
import { saveScrollReducer } from './slices/saveScrollSlice';
import { selectedReducer } from './slices/selectedSlice';
import { blankSliceReducer } from './slices/blanksSlice';
import { objectEquipmentReducer } from './slices/equipmentSlice';
import { objectApplicationReducer } from './slices/applicationSlice';

const rootReducer = combineReducers({
  auth: AuthSlice,
  correspondence: CorrespondenceSlice,
  user: UserSlice,
  ok: OKSlice,
  notification: NotificationSlice,
  outCorSlice: OutCorSlice,
  receiversSlice: ReceiversSlice,
  objects: ObjectSlice,
  employeeAccounting: EmployeeAccountingSlice,
  tasks: TaskSlice,
  saveScroll: saveScrollReducer,
  selected: selectedReducer,
  blanks: blankSliceReducer,
  objectEquipment: objectEquipmentReducer,
  ObjectApplication: objectApplicationReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: true
      })
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
