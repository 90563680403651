import { instanceWithoutToken } from './api';
import { ServerString } from '../config';

export const apiAuth = {
  registration: (FormData: FormData) => {
    return instanceWithoutToken.post(
      `${ServerString}/users/register/`,
      FormData
    );
  },
  checkEmail: (email: string) => {
    const data = new FormData();
    data.append('email', email);

    return instanceWithoutToken.post(
      `${ServerString}/employees/email/check/`,
      data
    );
  }
};
