import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFrequentPeoples, INewOutCorData } from '../types/outCorTypes';
import { getCurDate } from '../../helpers/utils';

interface IOutCorSliceState {
  outSearchParams: any;
  isOutCorLoading: boolean;
  newOutCorData: INewOutCorData | any;
  showDelCorModal: boolean;
  curOutCor: any;
  outCorList: any;
  curOutPage: number;
}

const initialState: IOutCorSliceState = {
  outSearchParams: null,
  isOutCorLoading: false,
  outCorList: [],
  showDelCorModal: false,
  curOutCor: {},
  curOutPage: 1,
  newOutCorData: {
    is_valid: true,
    date: getCurDate(),
    number: null,
    html: null,
    courier_file: null,
    courier_file_state: null,
    subject: null,
    related_to: null,
    signatory: null,
    initiator: null,
    summary: null,
    text: null,
    note: null,
    original_file_state: null,
    original_file: [],
    extra_upload: [],
    extra_upload_new: [],
    extra_files_links: [
      {
        inputId: 0,
        inputValue: ''
      }
    ],
    frequent_initiator: [],
    frequent_signatory: [],
    project_object: []
  }
};

export const outCorSlice = createSlice({
  name: 'outCorSlice',
  initialState,
  reducers: {
    //Статус прочитанно
    markAsAllVisited: state => {
      state.outCorList.results = state.outCorList.results.map((corr: any) => {
        return {
          ...corr,
          visited: true
        };
      });
    },
    markAsVisitedOutCorr: (
      state,
      action: PayloadAction<{ id: string; visited: boolean }>
    ) => {
      state.outCorList.results = state.outCorList.results.map((corr: any) => {
        if (corr.id === action.payload.id) {
          return {
            ...corr,
            visited: !action.payload.visited
          };
        }
        return corr;
      });
    },
    // URL параметры
    setOutSearchParams: (state, action) => {
      state.outSearchParams = action.payload;
    },
    // Сохранение и отправка
    setOutCorLoadingToggle: (state, action: PayloadAction<boolean>) => {
      state.isOutCorLoading = action.payload;
    },
    changeCurOutPage: (state, action: PayloadAction<number>) => {
      state.curOutPage = action.payload;
    },
    setOutCorEditData: (state, action) => {
      state.newOutCorData = action.payload;
    },
    setOutCreationInfo: (
      state,
      action: PayloadAction<{
        frequent_initiator: IFrequentPeoples[];
        frequent_signatory: IFrequentPeoples[];
        next_number: number;
      }>
    ) => {
      state.newOutCorData.number = `${action.payload.next_number}`;
      state.newOutCorData.frequent_initiator =
        action.payload.frequent_initiator.map(el => {
          return {
            label: `${el.last_name} ${el.first_name[0]}.${
              el.patronymic && el.patronymic[0]
            }. ${el.position && `- ${el.position}`}`,
            value: el.id,
            id: el.id
          };
        });
      state.newOutCorData.frequent_signatory =
        action.payload.frequent_signatory.map(el => {
          return {
            label: `${el.last_name} ${el.first_name[0]}.${
              el.patronymic && el.patronymic[0]
            }. ${el.position && `- ${el.position}`}`,
            value: el.id,
            id: el.id
          };
        });
    },
    changeOutCorStatus: (state, action) => {
      state.outCorList.results = state.outCorList.results.map((el: any) => {
        if (el.id === action.payload.id) {
          el.status = action.payload.status;
          return el;
        } else {
          return el;
        }
      });
    },
    setOutCorProjectObject: (state, action) => {
      state.newOutCorData.project_object = action.payload;
    },
    setOutCorOriginalFile: (state, action) => {
      state.newOutCorData.original_file = action.payload;
    },
    setOutCorExtraFile: (state, action: PayloadAction<File>) => {
      state.newOutCorData.extra_upload = [
        ...state.newOutCorData.extra_upload,
        action.payload
      ];
    },
    setOutCorExtraNewFile: (state, action: PayloadAction<File>) => {
      state.newOutCorData.extra_upload_new = [
        ...state.newOutCorData.extra_upload_new,
        action.payload
      ];
    },
    delOutCorExtraFile: (state, action: PayloadAction<string>) => {
      state.newOutCorData.extra_upload =
        state.newOutCorData.extra_upload.filter(
          (el: any) => el.name !== action.payload
        );
      state.newOutCorData.extra_upload_new =
        state.newOutCorData.extra_upload_new.filter(
          (el: any) => el.name !== action.payload
        );
    },
    addOutCorExtraLinkInput: state => {
      state.newOutCorData.extra_files_links.push({
        inputId: state.newOutCorData.extra_files_links.length,
        inputValue: ''
      });
    },
    delOutCorExtraLinkInput: (state, action) => {
      if (state.newOutCorData.extra_files_links.length === 1) {
        state.newOutCorData.extra_files_links = [
          {
            inputValue: '',
            inputId: 0
          }
        ];
      } else {
        state.newOutCorData.extra_files_links =
          state.newOutCorData.extra_files_links.filter(
            (linkInput: { inputValue: string; inputId: number }) => {
              return linkInput.inputId !== action.payload;
            }
          );
      }
    },
    setOutCorExtraLinkInputValue: (state, action) => {
      state.newOutCorData.extra_files_links =
        state.newOutCorData.extra_files_links.map((input: any) => {
          if (input.inputId === action.payload.inputId) {
            return {
              inputId: action.payload.inputId,
              inputValue: action.payload.inputValue
            };
          } else {
            return input;
          }
        });
    },
    deleteNewOutCorDataInputValue: (state, action) => {
      state.newOutCorData[action.payload.name] = state.newOutCorData[
        action.payload.name
      ].filter((el: any) => el !== action.payload.value);
    },
    changeNewOutCorDataInputValue: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.newOutCorData[action.payload.name] = action.payload.value;
    },
    setOutCorList: (state, action) => {
      state.outCorList = action.payload;
    },
    showDelCorModalToggle: (state, action: PayloadAction<boolean>) => {
      state.showDelCorModal = action.payload;
    },
    setCurOutCor: (state, action: PayloadAction<any>) => {
      state.curOutCor = action.payload;
    },
    delOutCorFromStore: (state, action: PayloadAction<string>) => {
      state.outCorList.results = state.outCorList.results.filter((el: any) => {
        return el.id !== action.payload;
      });
    },
    clearOutCorData: state => {
      state.newOutCorData = initialState.newOutCorData;
    }
  }
});

export default outCorSlice.reducer;
export const {
  markAsAllVisited,
  markAsVisitedOutCorr,
  clearOutCorData,
  setOutCorEditData,
  delOutCorFromStore,
  setCurOutCor,
  showDelCorModalToggle,
  setOutCorList,
  setOutCreationInfo,
  delOutCorExtraFile,
  setOutCorExtraFile,
  setOutCorOriginalFile,
  addOutCorExtraLinkInput,
  delOutCorExtraLinkInput,
  setOutCorExtraLinkInputValue,
  changeOutCorStatus,
  changeCurOutPage,
  changeNewOutCorDataInputValue,
  setOutCorExtraNewFile,
  setOutCorLoadingToggle,
  setOutSearchParams
} = outCorSlice.actions;
