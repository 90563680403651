import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type TValueLabel = {
  value: number;
  label: string;
};

interface GeneratedInputListEquipment {
  category: TValueLabel;
  model: TValueLabel;
  manufacturer: TValueLabel;
  system: TValueLabel;
  subCategory: TValueLabel;
  serialNumber: string;
  identifier: string;
  projectLabel: string;
}

interface BlanksSliceState {
  objectEquipment: any[];
  objectSystemEquipment: any[];
  objectModelEquipment: any[];
  objectManufacturerEquipment: any[];
  objectCategoryEquipment: any[];
  objectSubCategoryEquipment: any[];
  generatedInputListEquipment: GeneratedInputListEquipment[] | any[];
  generatedInputListLabelEquipment: any[] | any | null;
  objectSomeEquipment: any;
  originalFile: File | null | any;
  originalFileFilling: File | null | any;
  originalFileLoading: File | null | any;
  files: any;
  object: any;
  newObjectEquipment: any;
  newSystemEquipment: any;
  newModelEquipment: any;
  newManufacturerEquipment: any;
}

const initialState: BlanksSliceState = {
  objectEquipment: [],
  objectSystemEquipment: [],
  objectManufacturerEquipment: [],
  objectCategoryEquipment: [],
  objectSubCategoryEquipment: [],
  object: null,
  newObjectEquipment: {},
  objectModelEquipment: [],
  newModelEquipment: {},
  newSystemEquipment: {},
  newManufacturerEquipment: {},
  generatedInputListEquipment: [],
  generatedInputListLabelEquipment: [],
  objectSomeEquipment: {},
  originalFile: [],
  originalFileFilling: [],
  originalFileLoading: [],
  files: {}
};

export const ObjectEquipment = createSlice({
  name: 'objectEquipmentSlice',
  initialState,
  reducers: {
    setObjectEquipment: (state, action) => {
      state.objectEquipment = action.payload;
    },
    changeObjectEquipment: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.newObjectEquipment[action.payload.name] = action.payload.value;
    },
    setObjectSystemEquipment: (state, action) => {
      state.objectSystemEquipment = action.payload;
    },
    setObjectModelEquipment: (state, action) => {
      state.objectModelEquipment = action.payload;
    },
    setObjectManufacturerEquipment: (state, action) => {
      state.objectManufacturerEquipment = action.payload;
    },
    setObjectCategoryEquipment: (state, action) => {
      state.objectCategoryEquipment = action.payload;
    },
    setObjectSubCategoryEquipment: (state, action) => {
      state.objectSubCategoryEquipment = action.payload;
    },
    setGeneratedInputListEquipment: (state, action) => {
      state.generatedInputListEquipment = [
        ...state.generatedInputListEquipment,
        action.payload
      ];
    },

    setObjectSomeEquipment: (state, action) => {
      state.objectSomeEquipment[action.payload.id] = action.payload;
    },

    changeSystemEquipment: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.newSystemEquipment[action.payload.name] = action.payload.value;
    },
    changeGeneratedInputListEquipment: (
      state,
      action: PayloadAction<{ name: string; value: any; index: number }>
    ) => {
      if (state.generatedInputListEquipment !== undefined) {
        state.generatedInputListEquipment[action.payload.index][
          action.payload.name
        ] = action.payload.value;
      }
    },
    changeGeneratedInputListEquipmentSelected: (
      state,
      action: PayloadAction<{ name: string; value: any; index: number }>
    ) => {
      if (state.generatedInputListEquipment !== undefined) {
        state.generatedInputListEquipment[action.payload.index][
          action.payload.name
        ] = action.payload.value;
      }
    },
    deleteGeneratedInputListEquipment: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.generatedInputListEquipment =
        state.generatedInputListEquipment.filter(
          (el: any, index) => index !== Number(action.payload.index)
        );
    },

    changeModelEquipment: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.newModelEquipment[action.payload.name] = action.payload.value;
    },
    changeManufacturerEquipment: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.newManufacturerEquipment[action.payload.name] =
        action.payload.value;
    },
    setOriginalFileEquipment: (state, action) => {
      state.originalFile = action.payload;
    },
    setOriginalFileEquipmentFilling: (state, action) => {
      state.originalFileFilling = action.payload;
    },
    setOriginalFileEquipmentLoading: (state, action) => {
      state.originalFileLoading = action.payload;
    },
    changeInputValueEquipment: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.files[action.payload.name] = action.payload.value;
    }
  }
});

export const { reducer: objectEquipmentReducer } = ObjectEquipment;
export const {
  setObjectEquipment,
  changeObjectEquipment,
  setObjectSystemEquipment,
  setObjectModelEquipment,
  changeModelEquipment,
  changeSystemEquipment,
  changeManufacturerEquipment,
  setObjectManufacturerEquipment,
  setObjectCategoryEquipment,
  setObjectSubCategoryEquipment,
  setGeneratedInputListEquipment,
  changeGeneratedInputListEquipment,
  deleteGeneratedInputListEquipment,
  setOriginalFileEquipment,
  changeInputValueEquipment,
  setOriginalFileEquipmentFilling,
  setOriginalFileEquipmentLoading
} = ObjectEquipment.actions;
