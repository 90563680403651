import React, { Suspense, useEffect } from 'react';
import AppRouter from './routes/AppRoute';
import { useAppDispatch, useTypedSelector } from './hooks/reduxHooks/redux';
import {
  getAllDepartments,
  getSubdivisions
} from './reduxStore/actions/okActions';
import {
  getUserData,
  getUserNotification,
  getUserPermissions
} from './reduxStore/actions/userActions';
import useSocket from './hooks/useSocket';
import { getTasks } from 'reduxStore/actions/tasksActions';
import { Spinner } from 'react-bootstrap';

const App = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('tokenAccess');
  const isAuth = useTypedSelector(state => state.auth.isLoggedIn);
  const isDocumentPage = window.location.pathname.includes('/s/');
  useSocket();

  useEffect(() => {
    if (token && isAuth && !isDocumentPage) {
      dispatch(getAllDepartments());
      dispatch(getUserData());
      dispatch(getSubdivisions());
      dispatch(getUserPermissions());
      dispatch(getUserNotification(1));
      dispatch(getTasks());
    }
  }, [dispatch, token, isAuth, isDocumentPage]);

  return (
    <Suspense
      fallback={
        <Spinner
          className={'ms-3 fs--1'}
          animation="border"
          role="status"
          size={'sm'}
          variant={'secondary'}
        />
      }
    >
      <AppRouter />
    </Suspense>
  );
};
export default App;
