import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from '../types/notificationTypes';

interface INotificationSliceState {
  notifications: INotification[] | [];
  notificationCounter: number;
  urgentNotification: INotification | null;
}

const initialState: INotificationSliceState = {
  notificationCounter: 0,
  notifications: [],
  urgentNotification: null
};

export const NotificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setAllNotifications: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = [...state.notifications, ...action.payload];
    },
    setNotificationCounter: (state, action: PayloadAction<number>) => {
      state.notificationCounter = action.payload;
    },
    addNewNotification: (state, action: PayloadAction<INotification>) => {
      state.notifications = [action.payload, ...state.notifications];
      state.notificationCounter = state.notificationCounter + 1;
    },
    markAsReadAll: state => {
      state.notifications = state.notifications.map((el: INotification) => {
        return {
          ...el,
          seen: true
        };
      });
      state.notificationCounter = 0;
    },
    markAsReadOne: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.map((el: INotification) => {
        if (el.id === action.payload) {
          return {
            ...el,
            seen: true
          };
        }
        return el;
      });
      state.notificationCounter = state.notificationCounter - 1;
    },
    setUrgentNotification: (
      state,
      action: PayloadAction<INotification | null>
    ) => {
      state.urgentNotification = action.payload;
    }
  }
});

export const {
  setAllNotifications,
  addNewNotification,
  markAsReadAll,
  markAsReadOne,
  setUrgentNotification,
  setNotificationCounter
} = NotificationSlice.actions;
export default NotificationSlice.reducer;
