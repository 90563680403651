import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContractorsProps,
  ICorrespondence,
  ICorrespondenceNextPrev,
  ICorrespondenceResult,
  IFrequentResolution,
  INewCorrDataReq
} from '../types/correspTypes';
import { getCurDate } from '../../helpers/utils';

interface ICorrespondenceSliceState {
  incSearchParams: any;
  isIncCorLoading: boolean;
  correspondence: ICorrespondence;
  showDelCorModal: boolean;
  curCorrespId: any;
  showCorrExistAlert: boolean;
  curIncPage: number;
  newCorData: INewCorrDataReq | any;
  frequentResolution: IFrequentResolution[];
  correspondenceIncNextPrev: ICorrespondenceNextPrev;
  correspondenceOutNextPrev: ICorrespondenceNextPrev;
  contractorsSearch: ContractorsProps[];
}

const initialState: ICorrespondenceSliceState = {
  incSearchParams: null,
  isIncCorLoading: false,
  showCorrExistAlert: false,
  correspondence: {
    next: null,
    previous: null,
    results: [],
    count: 0
  },
  correspondenceIncNextPrev: {
    next: null,
    prev: null
  },
  correspondenceOutNextPrev: {
    next: null,
    prev: null
  },
  curIncPage: 1,
  frequentResolution: [],
  showDelCorModal: false,
  curCorrespId: '',
  newCorData: {
    original_file_state: null,
    date: getCurDate(),
    number: null,
    related_to: null,
    contractor_name: null,
    contractor_email: null,
    document_name: null,
    external_number: null,
    external_date: null,
    summary: null,
    note: null,
    extra_files_links: [
      {
        inputValue: '',
        inputId: 0
      }
    ],
    resolution: [],
    text: null,
    original_file: [],
    extra_upload: [],
    extra_upload_new: []
  },
  contractorsSearch: []
};

export const CorrespondenceSlice = createSlice({
  name: 'corSlice',
  initialState,
  reducers: {
    setAllCorAsVisited: state => {
      state.correspondence.results = state.correspondence.results.map(
        (corr: any) => {
          return {
            ...corr,
            visited: true
          };
        }
      );
    },
    changeVisitedIncStatus: (
      state,
      action: PayloadAction<{ id: string; visited: boolean }>
    ) => {
      state.correspondence.results = state.correspondence.results.map(
        (corr: any) => {
          if (corr.id === action.payload.id) {
            return {
              ...corr,
              visited: !action.payload.visited
            };
          }
          return corr;
        }
      );
    },
    setShowCorrExistAlert: (state, action) => {
      state.showCorrExistAlert = action.payload;
    },
    setIncSearchParams: (state, action) => {
      state.incSearchParams = action.payload;
    },
    setIncCorLoadingToggle: (state, action: PayloadAction<boolean>) => {
      state.isIncCorLoading = action.payload;
    },
    changeCurIncPage: (state, action: PayloadAction<number>) => {
      state.curIncPage = action.payload;
    },
    setIncCorrespondence: (state, action: PayloadAction<ICorrespondence>) => {
      state.correspondence = action.payload;
    },
    showDelCorModalToggle: (state, action: PayloadAction<boolean>) => {
      state.showDelCorModal = action.payload;
    },
    deleteIncCor: (state, action: PayloadAction<string>) => {
      state.correspondence.results = state.correspondence.results.filter(
        (el: any) => el.id !== Number(action.payload)
      );
    },
    deleteExtraLinkInput: (state, action: PayloadAction<number>) => {
      if (state.newCorData.extra_files_links.length === 1) {
        state.newCorData.extra_files_links = [
          {
            inputValue: '',
            inputId: 0
          }
        ];
      } else {
        state.newCorData.extra_files_links =
          state.newCorData.extra_files_links.filter(
            (linkInput: { inputValue: string; inputId: number }) => {
              return linkInput.inputId !== action.payload;
            }
          );
      }
    },
    setDelCorrespId: (state, action: PayloadAction<string>) => {
      state.curCorrespId = action.payload;
    },
    changeNewCorDataInputValue: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      if (action.payload.name === 'extra_upload') {
        state.newCorData.extra_upload.push(action.payload.value);
      } else if (action.payload.name === 'extra_upload_new') {
        state.newCorData.extra_upload_new.push(action.payload.value);
      } else {
        state.newCorData[action.payload.name] = action.payload.value;
      }
    },
    setCorDataInputValue: (state, action) => {
      state.newCorData = action.payload;
    },
    setCorDataContractorsValue: (state, action) => {
      state.contractorsSearch = action.payload;
    },
    setFrequentResoliton: (
      state,
      action: PayloadAction<IFrequentResolution[]>
    ) => {
      state.frequentResolution = action.payload.map((el: any) => {
        return {
          label: `${el.last_name} 
          ${el.first_name[0]}.${el.patronymic[0] || ''}.
          ${el.position && `- ${el.position}`}`,
          id: el.id,
          value: el.id
        };
      });
    },
    clearNewCorData: state => {
      state.newCorData = initialState.newCorData;
    },
    delExtraFileById: (state, action: PayloadAction<string>) => {
      state.newCorData.extra_upload = state.newCorData.extra_upload.filter(
        (el: any) => el.name !== action.payload
      );
      state.newCorData.extra_upload_new =
        state.newCorData.extra_upload_new.filter(
          (el: any) => el.name !== action.payload
        );
    },
    changeCorStatus: (state, action: PayloadAction<any>) => {
      state.correspondence.results = state.correspondence.results.map(
        (el: ICorrespondenceResult) => {
          if (el.id === action.payload.id) {
            el.status = action.payload.status;
            return el;
          }
          return el;
        }
      );
    },
    addExtraLinkInput: state => {
      state.newCorData.extra_files_links.push({
        inputValue: '',
        inputId: state.newCorData.extra_files_links.length
      });
    },
    setExtraLinkInputValue: (
      state,
      action: PayloadAction<{ inputId: number; inputValue: string }>
    ) => {
      state.newCorData.extra_files_links =
        state.newCorData.extra_files_links.map((input: any) => {
          if (input.inputId === action.payload.inputId) {
            return {
              inputId: action.payload.inputId,
              inputValue: action.payload.inputValue
            };
          } else {
            return input;
          }
        });
    },
    setNextPrevIncCorrespondence: (
      state,
      action: PayloadAction<ICorrespondenceNextPrev>
    ) => {
      state.correspondenceIncNextPrev = action.payload;
    },
    setNextPrevOutCorrespondence: (
      state,
      action: PayloadAction<ICorrespondenceNextPrev>
    ) => {
      state.correspondenceOutNextPrev = action.payload;
    }
  }
});

export default CorrespondenceSlice.reducer;
export const {
  setAllCorAsVisited,
  changeVisitedIncStatus,
  setIncSearchParams,
  changeCurIncPage,
  deleteExtraLinkInput,
  setExtraLinkInputValue,
  addExtraLinkInput,
  setIncCorrespondence,
  setDelCorrespId,
  showDelCorModalToggle,
  deleteIncCor,
  changeNewCorDataInputValue,
  setCorDataInputValue,
  setShowCorrExistAlert,
  changeCorStatus,
  clearNewCorData,
  delExtraFileById,
  setFrequentResoliton,
  setIncCorLoadingToggle,
  setNextPrevIncCorrespondence,
  setNextPrevOutCorrespondence,
  setCorDataContractorsValue
} = CorrespondenceSlice.actions;
