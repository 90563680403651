import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import HeaderSCSLayout from 'components/headerCard/HeaderSCS';
import useTitle from '../hooks/useTitle';

interface AuthSimpleLayoutProps {
  children: React.ReactNode;
  title: string;
}

const AuthSimpleLayout: React.FC<AuthSimpleLayoutProps> = ({
  children,
  title
}) => {
  useTitle(title);
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <HeaderSCSLayout />
          <Card>
            <Card.Body className="p-4">{children}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayout;
