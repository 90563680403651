import React from 'react';
import { Card } from 'react-bootstrap';
import Logo from '../common/Logo';
import Flex from '../common/Flex';

const HeaderSCS = () => {
  return (
    <Card className="mb-3">
      <Card.Body className="px-2 py-3 d-flex">
        <Logo width={80} className="ms-1 ms-sm-4" />
        <Flex direction="column" className="fs--1 ps-3 font-Arial">
          <span className="text-1000 fw-bold fs-0 fs-sm-1">
            СпецСитиСтрой.online
          </span>
          <span className="pt-1 fs--1 fs-md-0" style={{ lineHeight: '18px' }}>
            Корпоративная информационно-аналитическая платформа
          </span>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default HeaderSCS;
