import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../common/Flex';
import SoftBadge from '../../common/SoftBadge';
import {
  useAppDispatch,
  useTypedSelector
} from '../../../hooks/reduxHooks/redux';
import { selectedActions } from 'reduxStore/slices/selectedSlice';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

interface NavbarVerticalMenuItemProps {
  route: any;
}

const NavbarVerticalMenuItem: React.FC<NavbarVerticalMenuItemProps> = ({
  route
}) => {
  const notCounter = useTypedSelector(
    state => state.notification.notificationCounter
  );
  const taskCounter = useTypedSelector(state => state.tasks.tasks);
  const taskNotCompleted = taskCounter.filter(el => el.completed === false);
  const taskNotCompletedLength = taskNotCompleted.length;

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(selectedActions.setSelected({ selected: '' }));
  };

  return (
    <Flex alignItems="center">
      {route.icon && (
        <>
          {route.icon !== 'fa-file-signature' ? (
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={route.icon} />
            </span>
          ) : (
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={faFileSignature} />
            </span>
          )}
        </>
      )}
      <span
        className="nav-link-text"
        onClick={() => {
          handleClick();
        }}
      >
        {route.name}
      </span>
      {route.name === 'Уведомления' && notCounter !== 0 && (
        <SoftBadge pill bg={'warning'} className="ms-2">
          {notCounter}
        </SoftBadge>
      )}
      {route.name === 'Задачи' && taskNotCompletedLength > 0 && (
        <SoftBadge pill bg={'warning'} className="ms-2">
          {taskNotCompletedLength}
        </SoftBadge>
      )}
    </Flex>
  );
};

export default React.memo(NavbarVerticalMenuItem);
