import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { IRoute, privateRoutes, publicRoutes } from './routes';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import { LoginLazy } from 'components/authentication/Login/Login.async';
import CommonLayout from '../layouts/CommonLayout';
import MainLayout from 'layouts/MainLayout';
import { DefaultDashboardLazy } from '../dashboards/DefaultDashboard.async';
import { useTypedSelector } from '../hooks/reduxHooks/redux';
import { PoliceLazy } from 'pages/Police/Police.async';
import { TermsLazy } from 'pages/Terms/Terms.async';
import { Spinner } from 'react-bootstrap';

const AppRouter = () => {
  const { isLoggedIn } = useTypedSelector(state => state.auth);
  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route
            path={'/'}
            element={
              <MainLayout title={'CпецСитиСтрой.online'}>
                <DefaultDashboardLazy />
              </MainLayout>
            }
          />
          {privateRoutes.map((page: any) => {
            return (
              <Route
                key={page.path}
                path={page.path}
                element={
                  <page.layout title={page.title}>
                    <Suspense
                      fallback={<Spinner animation="border" size="sm" />}
                    >
                      <page.Component />
                    </Suspense>
                  </page.layout>
                }
              />
            );
          })}
        </>
      ) : (
        <>
          <Route
            path="/"
            element={
              <AuthSimpleLayout title={'Вход'}>
                <LoginLazy />
              </AuthSimpleLayout>
            }
          />

          {publicRoutes.map((page: IRoute) => {
            return (
              <Route
                key={page.path}
                path={page.path}
                element={
                  <page.layout title={page.title}>
                    <Suspense
                      fallback={<Spinner animation="border" size="sm" />}
                    >
                      <page.Component />
                    </Suspense>
                  </page.layout>
                }
              />
            );
          })}
        </>
      )}

      <Route
        path="terms"
        element={
          <CommonLayout title={'Пользовательское соглашение'}>
            <TermsLazy />
          </CommonLayout>
        }
      />
      <Route
        path="police"
        element={
          <CommonLayout title={'Политика конфиденциальности'}>
            <PoliceLazy />
          </CommonLayout>
        }
      />
    </Routes>
  );
};

export default AppRouter;
