import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from '../../../context/Context';
import {
  useAppDispatch,
  useTypedSelector
} from '../../../hooks/reduxHooks/redux';
import { selectedActions } from 'reduxStore/slices/selectedSlice';

const CollapseItems = ({ route, isOpen }: any) => {
  const { pathname } = useLocation();

  const openCollapse = (childrens: any) => {
    const checkLink = (children: any) => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(
    isOpen ? isOpen : openCollapse(route.children)
  );
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(selectedActions.setSelected({ selected: '' }));
  };

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => {
          setOpen(!open);
          handleClick();
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  isOpen: PropTypes.bool,
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ routes }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = useTypedSelector(state => state.user.permissions);
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext<any>(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return routes.map((route: any) => {
    const isActive = route.to === location.pathname;
    const isDisabled =
      route.labelDisabledPermission !== undefined
        ? !permissions.includes(route.labelDisabledPermission)
        : false;

    if (isDisabled) {
      return null;
    }

    if (!route.children) {
      return (
        <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
          <NavLink
            end={route.exact}
            to={route.to}
            state={{ open: route.to === '/authentication-modal' }}
            onClick={e => {
              if (!isActive && !isDisabled) {
                navigate(route.to);
              } else {
                e.preventDefault();
              }
            }}
            className={classNames('nav-link cursor-pointer', {
              active: isActive
            })}
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
      );
    }
    return (
      <CollapseItems route={route} key={route.name} isOpen={route.isOpen} />
    );
  });
};

export default NavbarVerticalMenu;
