import React, { ReactNode, memo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import Logo from 'assets/icons/logo_scs.svg';
import classNames from 'classnames';
import './Header.scss';

type TagTitleType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
interface HeaderProps {
  title: string;
  titleIcon?: {
    svg?: {
      size?: SizeProp;
      icon?: IconProp;
    };
    img?: string;
    width?: number;
    height?: number;
    alt?: string;
  };
  buttons?: ReactNode;
  classNameCardHeader?: string;
  classNameCard?: string;
  children?: ReactNode;
  rightTitle?: string;
  rightIcon?: ReactNode;
  TopTitle?: ReactNode;
  subtitle?: string;
  description?: ReactNode;
  classNameTitle?: string;
  TagTitle?: TagTitleType;
  underTitle?: string;
  classNameContainerTitle?: string;
}

const Header = (props: HeaderProps) => {
  const {
    title,
    rightIcon,
    rightTitle,
    titleIcon = {},
    children,
    classNameCard,
    classNameCardHeader,
    buttons,
    TopTitle,
    subtitle,
    description,
    classNameTitle,
    TagTitle,
    classNameContainerTitle,
    underTitle
  } = props;
  const TagFunc = useCallback(() => {
    if (TagTitle) {
      return TagTitle;
    } else {
      return 'h4';
    }
  }, [TagTitle]);
  const Tag = TagFunc();

  return (
    <Card className={classNames(`mb-3`, classNameCard ? classNameCard : '')}>
      <Card.Header
        className={classNames(
          '',
          classNameCardHeader ? classNameCardHeader : ''
        )}
      >
        {TopTitle && TopTitle}
        <div
          className={classNames(
            '',
            classNameContainerTitle ? classNameContainerTitle : ''
          )}
        >
          <div className="container-title">
            <div className="d-flex align-items-center">
              {titleIcon.svg?.icon ? (
                <FontAwesomeIcon
                  icon={titleIcon.svg.icon}
                  width={titleIcon.height || 40}
                  height={titleIcon.width || 40}
                  size={titleIcon.svg.size ? titleIcon.svg.size : '1x'}
                  className="me-3"
                />
              ) : titleIcon.img ? (
                <img
                  src={titleIcon.img || Logo}
                  width={titleIcon.width || 40}
                  height={titleIcon.height || 40}
                  alt={titleIcon.alt || 'Логотип Компании'}
                  className="me-3"
                />
              ) : null}

              <Tag
                className={classNames(
                  'mb-0',
                  classNameTitle ? classNameTitle : ''
                )}
              >
                {title}
                {underTitle && (
                  <p className="fs-14 text-600 mb-0">{underTitle}</p>
                )}
              </Tag>
            </div>
            {buttons && buttons}
            {rightTitle && <span className="right-title">{rightTitle}</span>}
          </div>
          {subtitle && (
            <p className={'fs-14 text-700 w-75 text-ellipsis'}>{subtitle}</p>
          )}
          {description && description}
        </div>
        {rightIcon && rightIcon}
        {children}
      </Card.Header>
    </Card>
  );
};

export default memo(Header);
