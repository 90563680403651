//THUNK
import { apiUsers } from '../../api/api-user';
import { AppDispatch } from '../index';
import {
  setUserDataToStore,
  setUserPermissions,
  setProfileImage,
  updateUserSettings,
  setEmployeeDataToStore,
  clearEmployeeData
} from '../slices/userSlice';
import { store } from '../../index';
import instance from '../../api/api';
import {
  setAllNotifications,
  setNotificationCounter,
  setUrgentNotification
} from '../slices/notificationSlice';

export const getUserNotification = (page: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await instance.get(
        `/notifications/?page=${page}&page_size=10`
      );

      if (res.status === 200) {
        const data = res?.data?.results;
        dispatch(
          setUrgentNotification(
            data.urgent_notifications?.length !== 0
              ? data.urgent_notifications[0]
              : null
          )
        );
        dispatch(setNotificationCounter(data.unread_notifications));
        dispatch(setAllNotifications(data.notifications));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getUserPermissions = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const res = await apiUsers.getUserPermissions();

      if (res.status === 200) {
        dispatch(setUserPermissions(res.data.permissions));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getUserData = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const d = new Date();
      const res = await apiUsers.getUserData();

      if (res.status === 200) {
        localStorage.setItem('name', res.data.first_name);
        dispatch(setUserDataToStore(res.data));
        if (res.data.photo == null) {
          dispatch(setProfileImage(res.data.photo));
        } else {
          dispatch(
            setProfileImage(`${res.data.photo}?timestamp=${d.getTime()}`)
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeUserSettings = (fieldsName: string, value: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const formdata = new FormData();
      formdata.append(fieldsName, value);

      const res = await apiUsers.changeUserSettings(formdata);
      if (res.status === 200) {
        dispatch(updateUserSettings(res.data.settings.hidden_fields));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getEmployeeData = (employeeId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(clearEmployeeData());
      const res = await apiUsers.getEmployeeData(employeeId);
      if (res.status === 200) {
        dispatch(setEmployeeDataToStore(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkUserPermissions = (url: string) => {
  const permissions = store.getState().user.permissions;
  switch (url !== null) {
    case url.toLowerCase().includes('outcoradd'):
      if (
        !permissions.includes('correspondence.change_outgoingcorrespondence')
      ) {
        alert('У вас нет прав на просмотр данной корреспонденции.');
        window.location.pathname = '/';
      }
      break;
    case url.toLowerCase().includes('inccoradd'):
      if (
        !permissions.includes(
          'correspondence.change_outgoingcorrespondence' ||
            'correspondence.change_incomingcorrespondence'
        )
      ) {
        alert('У вас нет прав на просмотр данной корреспонденции.');
        window.location.pathname = '/';
      }
      break;
  }
};
