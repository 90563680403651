import React from 'react';
import classNames from 'classnames';
import Avatar from '../../components/common/Avatar';
import { ServerString } from '../../config';
import {
  IncomingCorrIcon,
  OutgoingCorrIcon
} from '../../assets/icons/CoresspondenceIcons';
import { INotification } from '../../reduxStore/types/notificationTypes';
import { SCSLogo } from '../../assets/icons/SCSIcons';
import { readOneNotification } from '../../reduxStore/actions/notification';
import { useAppDispatch } from '../../hooks/reduxHooks/redux';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import human from '../../assets/img/profile/human.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faClipboardList, faComment, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';

const getNotificationHandler = (event: any, navigate: any) => {
  switch (event?.origin) {
    case 'Task':
      setTimeout(() => {
        navigate('/modules/tasks');
      });
      break;
    case 'OutgoingCorrespondence':
      setTimeout(() => {
        navigate(`/modules/corespChain/out/${event?.target}`);
      }, 500);
      break;
    case 'IncomingCorrespondence':
      setTimeout(() => {
        navigate(`/modules/corespChain/inc/${event?.target}`);
      }, 500);
      break;
    case 'Application':
    case 'Comment':
      setTimeout(() => {
        navigate(`/modules/details_application/${event?.target}`);
      }, 500);
      break;
    default:
      console.log('');
  }
};

interface getNotificationIconProps {
  type: 'OutgoingCorrespondence' | 'IncomingCorrespondence' | string;
}

const getNotificationIcon: React.FC<getNotificationIconProps> = ({
  type
}): any => {
  switch (type) {
    case 'Task.Assigned':
    case 'Task.StatusChanged':
      return <FontAwesomeIcon size={'2x'} icon={faListAlt} />;
    case 'OutgoingCorrespondence.ResponsibleSent':
    case 'OutgoingCorrespondence.ResponsibleUpdated':
      return <OutgoingCorrIcon w={30} h={30} />;
    case 'IncomingCorrespondence.New':
      return <IncomingCorrIcon w={30} h={30} />;
    case 'IncomingCorrespondence.Forward':
    case 'OutgoingCorrespondence.Forward':
      return <Avatar src={human} />;
    case 'Comment.New':
      return <FontAwesomeIcon size={'2x'} icon={faComment} />; 
    case 'Application.Created':
      return <FontAwesomeIcon size={'2x'} icon={faClipboardList} />; 
    case 'Application.AssignedResponsible':
      return <FontAwesomeIcon size={'2x'} icon={faPeopleArrows} />; 
    default:
      return <SCSLogo />;
  }
};

interface NotificationProps {
  notification: INotification;
  flush?: boolean;
}

const Notification: React.FC<NotificationProps> = ({
  notification,
  flush = false
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div
      onClick={() => {
        if (!notification.seen) {
          dispatch(readOneNotification(notification.id));
        }
        getNotificationHandler(notification.event, navigate);
      }}
      className={classNames(
        'd-flex align-items-center cursor-pointer notification rounded-0 border-x-0 border-300 border-bottom-0',
        {
          'notification-unread': !notification.seen,
          'notification-flush': flush
        }
      )}
    >
      {notification?.message?.icon ? (
        <div className="notification-avatar me-3">
          <Avatar
            src={`${ServerString}${notification?.message?.icon}`}
            rounded={'circle'}
          />
        </div>
      ) : (
        <div className="notification-avatar me-3">
          {getNotificationIcon({
            type: `${notification?.event?.origin}.${notification?.event?.action}`
          })}
        </div>
      )}
      {/*BODY*/}
      <div>
        <h6 className={'m-0 text-1000 p-0'}>
          {notification?.message?.subject || ''}
        </h6>
        <ReactMarkdown
          linkTarget={'_blank'}
          className={'text-800 markDownMarginNone'}
        >
          {notification?.message?.text || ''}
        </ReactMarkdown>
        <p className={'notification-time'}>
          {moment(notification?.event.datetime).format(
            'HH:mm:ss - DD.MM.YYYYг'
          )}
        </p>
      </div>
    </div>
  );
};

export default Notification;
